import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Text } from 'rsuite'
import { TbBookmark, TbBookmarkFilled } from 'react-icons/tb'

import Card from 'react-bootstrap/Card'
import CardOptions from 'components/Card/CardOptions'
import { usePostContext } from 'components/Post/PostContext'

import { fetchapi } from 'lib/fetch'

function CardHeader({ onDelete }) {
	const { post, saved, setSaved, postdate, noLinks } = usePostContext()

	const color = '#FFAC2A'

	const iconSaved = <TbBookmarkFilled color={color} />
	const iconUnsaved = <TbBookmark />

	const [iconBookmark, setIconBookmark] = useState(iconUnsaved)
	useEffect(
		function () {
			if (post?.saved) {
				setSaved(post?.saved)
				setIconBookmark(iconSaved)
			}
		},
		[post?.saved]
	)

	async function toggleSavePost() {
		const action = saved ? 'unsave' : 'save'
		const res = await fetchapi(`/post/${post?.postid}/${action}`)

		if (res.success) {
			setSaved(!saved)

			if (action == 'unsave') {
				setIconBookmark(iconUnsaved)
			} else {
				setIconBookmark(iconSaved)
			}
		}
	}

	var title = <></>
	if (post?.title) {
		if (noLinks) {
			title = (
				<div className='post-card-title'>
					<h2>{post?.title}</h2>
				</div>
			)
		} else {
			title = (
				<Link className='post-card-title' to={'/post/' + post?.postid}>
					<h2>{post?.title}</h2>
				</Link>
			)
		}
	}

	return (
		<Card.Header>
			<span className='float-start'>
				<div className='post-card-details'>
					{noLinks ? (
						<span className='post-card-group'>
							{post?.groupname}
						</span>
					) : (
						<Link className='post-card-group' to={'/group/' + post?.groupid}>
							{post?.groupname}
						</Link>
					)}
					<br />
					<Link className='post-card-name' to={'/profile/' + post?.owner}>
						{post?.firstname} {post?.lastname}
					</Link>
					&nbsp;&bull;&nbsp;
					{noLinks ? (
						<span className='post-card-date'>{postdate}</span>
					) : (
						<Link to={'/post/' + post?.postid}>
							<span className='post-card-date'>{postdate}</span>
						</Link>
					)}
					{title}
				</div>
			</span>
			<span className='float-end'>
				<CardOptions postid={post?.postid} ownerid={post?.owner} onDelete={onDelete} />
				<IconButton
					className='save card-btn'
					icon={iconBookmark}
					onClick={toggleSavePost}
					data-saved={saved}
				/>
			</span>
		</Card.Header>
	)
}

export default CardHeader
