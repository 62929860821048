import PageNotFound from 'components/App/PageNotFound'
import HomeFrame from 'components/Home/HomeFrame'
import FeedFrame from 'components/Feed/FeedFrame'
import PostFrame from 'components/Post/PostFrame'
import ProfileFrame from 'components/Profile/ProfileFrame'
import GroupFrame from 'components/Groups/GroupFrame'
import ViewCourse from 'components/Courses/ViewCourse'
import ViewLesson from 'components/Courses/ViewLesson'
import MyCourses from 'components/Courses/MyCourses'
import Registration from 'components/Accounts/Registration'

const appRoutes = [
	{
		path: '/register',
		element: <PageNotFound />,
	},
	{
		path: '/register/:invite',
		element: <Registration />,
	},
	{
		path: '/',
		element: <HomeFrame />,
	},
	{
		path: '/post/:postid',
		element: <PostFrame />,
	},
	{
		path: '/profile',
		element: <ProfileFrame />,
	},
	{
		path: '/profile/:userid',
		element: <ProfileFrame />,
	},
	{
		path: '/saved',
		element: <FeedFrame src='saved' />,
	},
	{
		path: '/emerge',
		element: <FeedFrame />,
	},
	{
		path: '/group/:groupid',
		element: <GroupFrame />,
	},
	{
		path: '/courses',
		element: <MyCourses />,
	},
	{
		path: '/course/:courseid',
		element: <ViewCourse />,
	},
	{
		path: '/course/:courseid/:lessonid',
		element: <ViewLesson />,
	},
	{
		path: '*',
		element: <PageNotFound />,
	},
]

export default appRoutes
