import { Card } from 'react-bootstrap'
import { Placeholder, Stack } from 'rsuite'

export default function LoadingCard({ count }) {
	const cards = []

	for (let i = 0; i < count; i++) {
		cards[i] = (
			<Card key={i} className='post-card'>
				<Card.Header>
					<Placeholder.Paragraph
						rows={3}
						rowSpacing={0}
						style={{ paddingBottom: '10px' }}
						active
					/>
				</Card.Header>
				<Card.Body style={{ padding: '10px 20px 20px' }}>
					<Placeholder.Paragraph rows={7} active />
				</Card.Body>
				<Card.Footer style={{ padding: '10px 0' }}>
					<Stack direction='row' justifyContent='center' spacing={60}>
						<Stack.Item>
							<Placeholder.Graph width={42} height={42} active />
						</Stack.Item>
						<Stack.Item>
							<Placeholder.Graph width={42} height={42} active />
						</Stack.Item>
						<Stack.Item>
							<Placeholder.Graph width={42} height={42} active />
						</Stack.Item>
					</Stack>
				</Card.Footer>
			</Card>
		)
	}

	return <>{cards}</>
}
