import AppFrame from 'components/App/AppFrame'
import { Container, FlexboxGrid } from 'rsuite'

function HomeFrame() {
	return (
		<AppFrame className='home'>
			Welcome to the God Loves Art App!
		</AppFrame>
	)
}

export default HomeFrame
