export function swiperConfig(swiperRef) {
	if (swiperRef && swiperRef.current) {
		const swiperContainer = swiperRef.current
		const params = {
			navigation: true,
			pagination: true,
			injectStyles: [
				`
				.image-gallery {
					background-color: #FFF;
					border-bottom: 1px solid #CCC;
					padding: 15px;
				}
				.image-gallery img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
				.swiper-button-next,
				.swiper-button-prev {
					background-color: #1B1D20;
					outline: rgba(255,255,255,0.5) solid 2px;
					font-weight: 900;
					width: 40px;
					height: 40px;
					top: 50%;
					border-radius: 50%;
				}
				.swiper-button-next svg,
				.swiper-button-prev svg {
					width: unset;
					height: unset;
				}
				.swiper-button-next svg *,
				.swiper-button-prev svg * {
					fill: #fff;
				}
				.swiper-button-next.swiper-button-disabled,
				.swiper-button-prev.swiper-button-disabled {
					display: none;
				}
				.swiper-pagination-bullet {
					width: 10px;
					height: 10px;
					background-color: #1B1D20;
					border: 2px solid #1B1D20;
					outline: rgba(255,255,255,0.5) solid 2px;
					opacity: 1;
				}
				.swiper-pagination-bullet-active {
					background-color: #fff;
				}
				`,
			],
		}

		Object.assign(swiperContainer, params)
		swiperContainer.initialize()
	}
}
