import { useParams } from 'react-router-dom'
import AppFrame from 'components/App/AppFrame'
import Feed from 'components/Feed/Feed'
import ProfileHeader from 'components/Profile/ProfileHeader'
import { useGlobalContext } from 'components/App/GlobalContext'
import './ProfileFrame.css'

export default function ProfileFrame() {
	return (
		<AppFrame colSpan={24} xxl={12} xl={15} lg={18} md={24} sm={24} xs={24}>
			<ProfileFrameContent />
		</AppFrame>
	)
}

function ProfileFrameContent() {
	const {
		profile
	} = useGlobalContext()
	const params = useParams()
	const profileid = params?.userid || profile.id

	return (
		<>
			<ProfileHeader profileid={profileid} />
			<Feed profileid={profileid} src='profile' />
		</>
	)
}
