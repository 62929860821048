import { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { register } from 'swiper/element/bundle'
import { swiperConfig } from 'lib/imageGallery'
import parse from 'html-react-parser'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import Card from 'react-bootstrap/Card'
import { Stack, Button } from 'rsuite'
import { fetchapi } from 'lib/fetch'
import { usePostContext, PostProvider } from 'components/Post/PostContext'

register()

export default function FeedCard({ item, className, footer, onDelete, noLinks }) {
	return (
		<PostProvider initPost={item} noLinks={!!noLinks}>
			<FeedCardContent className={className || ''} footer={footer} onDelete={onDelete} />
		</PostProvider>
	)
}

function FeedCardContent({ className, footer, onDelete }) {
	const { post, attachments } = usePostContext()

	if (footer === undefined) {
		footer = true
	}

	const navigate = useNavigate()
	const swiperRef = useRef(null)
	const [body, setBody] = useState('')
	const saveClass = ['save', 'float-end']
	const [isDeleted, setIsDeleted] = useState(false)

	useEffect(() => {
		if (post?.body) {
			setBody(post.body)
		}

		if (attachments?.length > 0) {
			swiperConfig(swiperRef)
		}

		if (post?.saved) {
			saveClass.push('saved')
		}
	}, [post])

	function gotoPost() {
		navigate(`/post/${post.postid}`)
	}

	async function deletePost() {
		setIsDeleted(true)
		onDelete && onDelete()
	}

	async function undeletePost() {
		const res = await fetchapi(`/post/${post.postid}/untrash`)

		if (res.success) {
			setIsDeleted(false)
		}
	}

	return isDeleted ? (
		<Card className={`post-card post-deleted ${className || ''}`}>
			<Card.Body>
				<Stack direction='row' alignItems='center' spacing={15}>
					<Stack.Item alignSelf='flex-start'>
						<div className='undo-text'>This post has been deleted.</div>
					</Stack.Item>
					<Stack.Item alignSelf='flex-end'>
						<Button appearance='ghost' onClick={undeletePost}>
							Undo
						</Button>
					</Stack.Item>
				</Stack>
			</Card.Body>
		</Card>
	) : (
		<>
			<Card className={`post-card ${className || ''} ${footer ? 'no-footer' : ''}`}>
				<CardHeader onDelete={deletePost} />
				<Card.Body>
					{attachments?.length > 0 && (
						<div className='image-gallery'>
							<swiper-container ref={swiperRef} init='false'>
								{attachments.map(function (a) {
									return (
										<swiper-slide key={a.id} lazy='true'>
											<div className='box box-1x1'>
												<div className='content'>
													<img src={a.url} loading='lazy' onClick={gotoPost} alt='' />
												</div>
											</div>
										</swiper-slide>
									)
								})}
							</swiper-container>
						</div>
					)}
					<div className='card-text'>{(body && parse(body)) || ''}</div>
				</Card.Body>
				{footer && <CardFooter />}
			</Card>
		</>
	)
}
