import { useEffect, useState } from 'react'
import { fetchapi } from 'lib/fetch'
import AdminFrame from '../AdminFrame'
import {
	Container,
	Content,
	Stack,
	Table,
	Text,
	Progress,
	Navbar,
	Nav
} from 'rsuite'
import AppLink from 'components/App/AppLink'
import { useParams } from 'react-router-dom/dist'
import { dayjs, DATE_FORMAT } from 'lib/datetime'
import Coursework from './Coursework'
import { Link } from 'react-router-dom'
import { TbArrowLeft } from 'react-icons/tb'

import './Roster.css'

const { Column, HeaderCell, Cell } = Table

export default function Roster() {
	const { courseid } = useParams()
	const [course, setCourse] = useState()
	const [roster, setRoster] = useState()
	const [sortColumn, setSortColumn] = useState()
	const [sortType, setSortType] = useState()
	const [userid, setUserid] = useState()
	const [open, setOpen] = useState(false)
	const [title, setTitle] = useState('')

	useEffect(() => {
		; (async () => {
			const response = await fetchapi(`/admin/course/${courseid}/roster`)

			if (response?.success) {
				setCourse(response.course)
				setRoster(response.roster)
			}
		})()
	}, [courseid])

	const handleSortColumn = (sortColumn, sortType) => {
		const data = [...roster].sort((v1, v2) => {
			if (v1[sortColumn] < v2[sortColumn]) {
				return -1
			} else if (v1[sortColumn] > v2[sortColumn]) {
				return 1
			} else {
				return 0
			}
		})

		if (sortType === 'desc') {
			data.reverse()
		}

		setRoster(data)
		setSortColumn(sortColumn);
		setSortType(sortType);
	};

	function viewCoursework(rowData) {
		setUserid(rowData.userid)
		setTitle(`${rowData.firstname} ${rowData.lastname}`)
		setOpen(true)
	}

	return (
		<AdminFrame>
			<Container className='has-subnav'>
				<Content>
					<Navbar className='subnav'>
						<Nav>
							<Nav.Item
								eventKey='back'
								as={AppLink}
								href='/admin/courses'
								icon={<TbArrowLeft />}
							>
								Back to Courses
							</Nav.Item>
						</Nav>
					</Navbar>
					<h1>Roster</h1>
					<Stack
						direction='column'
						spacing={10}
						alignItems='stretch'
						style={{ width: '100%', marginBottom: '2em' }}
					>
						<Stack.Item>
							<Stack directon='row' spacing={10}>
								<Stack.Item>
									<Text size='xl'>Course:</Text>
								</Stack.Item>
								<Stack.Item>
									<Text size='xl' weight='bold'>
										{course?.name}
									</Text>
								</Stack.Item>
							</Stack>
						</Stack.Item>
						<Stack.Item>
							<Stack directon='row' spacing={10}>
								<Stack.Item>
									<Text size='xl'>Course Dates:</Text>
								</Stack.Item>
								<Stack.Item>
									<Text size='xl'>
										{dayjs(course?.startdate).format(DATE_FORMAT)}
										&nbsp;&rarr;&nbsp;
										{dayjs(course?.enddate).format(DATE_FORMAT)}
									</Text>
								</Stack.Item>
							</Stack>
						</Stack.Item>
					</Stack>
					<Table
						className='course-roster'
						hover
						autoHeight={true}
						data={roster}
						rowKey='userid'
						sortColumn={sortColumn}
						sortType={sortType}
						onSortColumn={handleSortColumn}
						onRowClick={viewCoursework}
					>
						<Column resizable sortable flexGrow={1}>
							<HeaderCell>First Name</HeaderCell>
							<Cell dataKey='firstname' />
						</Column>
						<Column resizable sortable flexGrow={1}>
							<HeaderCell>Last Name</HeaderCell>
							<Cell dataKey='lastname' />
						</Column>
						<Column resizable sortable flexGrow={2}>
							<HeaderCell>Email</HeaderCell>
							<Cell dataKey='email' />
						</Column>
						<Column resizable sortable flexGrow={3}>
							<HeaderCell>Progress</HeaderCell>
							<Cell dataKey='progress'>
								{(rowData) => (
									<Progress.Line
										percent={rowData.progress}
									/>
								)}
							</Cell>
						</Column>
					</Table>
					<Coursework
						userid={userid}
						courseid={courseid}
						open={open}
						onClose={() => setOpen(false)}
						title={title}
					/>
				</Content>
			</Container>
		</AdminFrame>
	)
}