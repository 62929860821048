import { Dropdown, IconButton } from 'rsuite'
import { TbSquareKey, TbUserPlus, TbGauge, TbBook2, TbSettings } from 'react-icons/tb'
import { useGlobalContext } from 'components/App/GlobalContext'

import AppLink from 'components/App/AppLink'
import { useEffect } from 'react'

function AdminMenu() {
	const {
		isAdmin
	} = useGlobalContext()

	function iconButton(props) {
		return (
			<IconButton {...props} icon={<TbSquareKey size={30} />} className='utility-btn' />
		)
	}

	if (!isAdmin) {
		return <></>
	} else {
		return (
			<Dropdown
				className='header-nav-menu'
				title='Admin'
				renderToggle={iconButton}
				placement='bottomEnd'
			>
				<Dropdown.Item panel style={{ padding: 10, textTransform: 'uppercase' }}>
					<p>
						<strong>Administration</strong>
					</p>
				</Dropdown.Item>
				<Dropdown.Item divider />
				<Dropdown.Item as={AppLink} href='/admin/users/invite' icon={<TbUserPlus />}>
					Invite Users
				</Dropdown.Item>
				<Dropdown.Item divider />
				<Dropdown.Item as={AppLink} href='/admin' icon={<TbGauge />}>
					Dashboard
				</Dropdown.Item>
				<Dropdown.Item as={AppLink} href='/admin/courses' icon={<TbBook2 />}>
					Courses
				</Dropdown.Item>
				<Dropdown.Item divider />
				<Dropdown.Item as={AppLink} href='/admin/settings' icon={<TbSettings />}>
					Settings
				</Dropdown.Item>
			</Dropdown>
		)
	}
}

export default AdminMenu
