import { useParams } from 'react-router-dom'
import AppFrame from 'components/App/AppFrame'
import GroupFeed from 'components/Groups/GroupFeed'

export default function GroupFrame() {
	const params = useParams()
	const groupid = params.groupid || 1

	return (
		<AppFrame
			colSpan={24}
			xxl={12}
			xl={15}
			lg={18}
			md={24}
			sm={24}
			xs={24}
			groupid={groupid}
		>
			<GroupFeed groupid={groupid} />
		</AppFrame>
	)
}
