import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Grid,
	Panel,
	Row,
	Col,
	Accordion,
	ButtonGroup,
	Button,
	Dropdown,
	Text,
	Modal,
	Stack,
	Progress,
} from 'rsuite'
import {
	TbLock,
	TbCircleCheckFilled,
	TbDots,
	TbArrowBigRightFilled,
	TbSwitchVertical,
	TbCaretDownFilled,
	TbTrash,
	TbCopy,
} from 'react-icons/tb'
import parse from 'html-react-parser'

import Editable from 'components/Admin/Editable'
import { useCourseContext } from './CourseContext'

import { fetchapi } from 'lib/fetch'
import { dayjs, DATE_FORMAT } from 'lib/datetime'
import { useGlobalContext } from 'components/App/GlobalContext'

const COMPLETED = 'completed'
const INPROGRESS = 'inprogress'
const CURRENT = 'current'
const LOCKED = 'locked'
const ADMIN = 'admin'

export default function LessonCards({ onUpdate }) {
	const { isAdmin, lessons } = useCourseContext()

	const numLessons = lessons.length

	var current = lessons.length - 1
	for (let i = lessons.length - 1; i >= 0; i--) {
		if (dayjs().isSameOrAfter(dayjs(lessons[i].releasedate), 'day')) {
			current = i
			break
		}
	}

	if (numLessons === 0) {
		return <></>
	} else {
		return (
			<>
				{lessons.map(function (v, i) {
					const isCurrent = i === current
					const status = getLessonStatus(v, isCurrent, isAdmin)

					const header = (
						<LessonCardHeader
							lesson={v}
							index={i + 1}
							status={status}
							isAdmin={isAdmin}
							onUpdate={onUpdate}
						/>
					)
					const headerClass = isAdmin
						? 'lesson-card lesson-admin'
						: `lesson-card lesson-${status}`

					var complete = <></>

					if (!isAdmin && status === COMPLETED) {
						complete = (
							<>
								Completed:{' '}
								<span className='complete-date'>
									{dayjs(v.completedate).format(DATE_FORMAT)}
								</span>
							</>
						)
					}

					const available = dayjs(v.releasedate).format(DATE_FORMAT)

					return (
						<Panel key={v.id} header={header} bordered className={headerClass}>
							<Accordion>
								<Accordion.Panel
									className='lesson-details'
									header={<strong>Details</strong>}
									collapsible
								>
									<Grid fluid>
										<Row gutter={15}>
											<Col xs={24} sm={24} md={24} lg={12} xl={12}>
												Available : <span className='release-date'>{available}</span>
											</Col>
											<Col xs={12}>{complete}</Col>
										</Row>
										<Row>
											<Col xs={24} sm={24} md={24} lg={12} xl={12}>
												{v?.shortdescription ? parse(v?.shortdescription) : ''}
											</Col>
										</Row>
									</Grid>
								</Accordion.Panel>
							</Accordion>
						</Panel>
					)
				})}
			</>
		)
	}
}

function LessonCardHeader({ lesson, index, status, onUpdate }) {
	const { isMobile } = useGlobalContext()
	const { isAdmin, gotoLesson } = useCourseContext()

	const navigateTo = useNavigate()
	const [deleteDialog, setDeleteDialog] = useState(false)

	function getIcon() {
		if (isAdmin) {
			return <TbSwitchVertical className='lesson-icon' />
		}
		switch (status) {
			case COMPLETED:
				return <TbCircleCheckFilled className='lesson-icon' />

			case INPROGRESS:
				return <TbDots className='lesson-icon' />

			case CURRENT:
				return <TbArrowBigRightFilled className='lesson-icon' />

			case LOCKED:
				return <TbLock className='lesson-icon' />

			default:
				return <></>
		}
	}

	function getStatusString() {
		if (isAdmin) {
			return 'View/Edit'
		}

		switch (status) {
			case COMPLETED:
				return 'Completed'

			case INPROGRESS:
				return 'In Progress'

			case CURRENT:
				return 'Start'

			case LOCKED:
				return dayjs(lesson.releasedate).format(DATE_FORMAT)

			default:
				return ''
		}
	}

	async function copyLesson() {
		try {
			const response = await fetchapi(`/admin/lesson/${lesson.id}/copy`)

			if (response?.success) {
				onUpdate && onUpdate()
			}
		} catch (err) {
			console.log(err)
		}
	}

	async function deleteLesson() {
		try {
			const response = await fetchapi(`/admin/lesson/${lesson.id}/delete`)

			if (response?.success) {
				onUpdate && onUpdate()
				setDeleteDialog(false)
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Stack className='lesson-card-header' direction={isMobile ? 'column' : 'row'} alignItems='flex-start'>
				<Stack.Item>{getIcon()}</Stack.Item>
				<Stack.Item flex={1}>
					{isAdmin ? (
						<Editable
							as='div'
							className='lesson-title editable'
							defaultValue={lesson.title}
							field='lessonTitle'
							id={lesson.id}
							placeholder='Enter the lesson title'
						/>
					) : (
						<h3 className='lesson-title'>{lesson.title}</h3>
					)}
				</Stack.Item>
				<Stack.Item className='admin-split-btn-group'>
					<ButtonGroup>
						<Button
							className='lesson-status'
							onClick={() => gotoLesson(lesson.sortorder)}
						>
							{getStatusString()}
							{status === INPROGRESS && (
								<Progress.Line
									percent={lesson.progress}
									strokeColor='#ffac2a'
									strokeWidth={5}
									showInfo={false}
								/>
							)}
						</Button>
						{isAdmin ? (
							<Dropdown
								className='admin-split-btn'
								icon={<TbCaretDownFilled />}
								noCaret
								placement='bottomEnd'
							>
								<Dropdown.Item onSelect={() => copyLesson()}>
									<Text>
										<TbCopy />
										Duplicate Lesson
									</Text>
								</Dropdown.Item>
								<Dropdown.Separator />
								<Dropdown.Item onSelect={() => setDeleteDialog(true)}>
									<Text color='red'>
										<TbTrash />
										Delete
									</Text>
								</Dropdown.Item>
							</Dropdown>
						) : (
							<></>
						)}
					</ButtonGroup>
					{isAdmin && (
						<Modal
							size='xs'
							backdrop
							open={deleteDialog}
							onClose={() => setDeleteDialog(false)}
						>
							<Modal.Header>
								<Modal.Title>Delete Step?</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are you sure you want to delete lesson:
								<Panel bordered style={{ borderColor: 'red' }}>
									<strong>{lesson.title}</strong>
								</Panel>
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => setDeleteDialog(false)} appearance='subtle'>
									Cancel
								</Button>
								<Button onClick={() => deleteLesson()} appearance='primary' color='red'>
									DELETE
								</Button>
							</Modal.Footer>
						</Modal>
					)}
				</Stack.Item>
			</Stack>
		</>
	)
}

function getLessonStatus(lesson, current, isAdmin) {
	if (isAdmin) {
		return ADMIN
	}

	if (dayjs().isSameOrAfter(dayjs(lesson.releasedate))) {
		if (lesson.completedate) {
			return COMPLETED
		} else if (current) {
			return CURRENT
		} else {
			return INPROGRESS
		}
	} else {
		return LOCKED
	}
}
