/*
 * Ref: https://www.pluralsight.com/guides/how-to-communicate-between-independent-components-in-reactjs
 */
const EventsBus = {
	on(event, callback) {
		document.addEventListener(event, (e) => callback(e.detail))
	},
	dispatch(event, data) {
		document.dispatchEvent(new CustomEvent(event, { detail: data }))
	},
	remove(event, callback) {
		document.removeEventListener(event, callback)
	},
}

export default EventsBus
