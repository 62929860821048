import { useState } from 'react'
import {
	Container,
	Content,
	Input,
	ButtonToolbar,
	Button,
	Panel,
	FlexboxGrid,
	Col,
	Stack,
} from 'rsuite'
import { useGlobalContext } from 'components/App/GlobalContext'
import './SignIn.css'
import Password from 'components/Accounts/Password'
import { pathPrefix } from 'lib/utils'

export default function SignIn() {
	const {
		login,
	} = useGlobalContext()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	function updateForm(form) {
		setEmail(form.email)
		setPassword(form.password)
	}

	return (
		<div className='login'>
			<Container>
				<Content>
					<FlexboxGrid justify='center'>
						<FlexboxGrid.Item className='signin-container' as={Col} colSpan={18} md={12} lg={8} xl={6}>
							<Panel header={<SigninHeader />} bordered>
								<Stack direction='column' spacing={20}>
									<Stack.Item alignSelf='stretch'>
										<label>
											Email Address
										</label>
										<Input type='email' autoCapitalize='off' onChange={setEmail} size='lg' />
									</Stack.Item>
									<Stack.Item alignSelf='stretch'>
										<label>
											Password
										</label>
										<Password autoComplete='off' onChange={setPassword} size='lg' />
									</Stack.Item>
									<Stack.Item alignSelf='stretch'>
										<ButtonToolbar>
											<Button appearance='primary' onClick={() => login(email, password)}>
												Sign in
											</Button>
											<Button appearance='link'>Forgot password?</Button>
										</ButtonToolbar>
									</Stack.Item>
								</Stack>
							</Panel>
						</FlexboxGrid.Item>
					</FlexboxGrid>
				</Content>
			</Container>
		</div>
	)
}

function SigninHeader() {
	return <img src={`${pathPrefix}/images/logo-color.svg`} />
}
