import { useState } from 'react'
import { IconButton, Drawer, Placeholder } from 'rsuite'
import { TbBell } from 'react-icons/tb'

function Notifications() {
	const [panel, setPanel] = useState(false)
	//const [ notifications, setNotifications ] = useState([]);

	function togglePanel() {
		setPanel(!panel)
	}

	return (
		<>
			<IconButton
				title='Notifications'
				icon={<TbBell size={30} />}
				className='utility-btn'
				onClick={togglePanel}
				data-open={panel}
				style={{ marginTop: '-5' }}
			/>
			<Drawer
				open={panel}
				onClose={() => setPanel(false)}
				className='notifications-panel'
			>
				<Drawer.Header>
					<Drawer.Title>Notifications</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					<Placeholder.Paragraph />
				</Drawer.Body>
			</Drawer>
		</>
	)
}

export default Notifications
