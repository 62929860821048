import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Content, Grid, Row, Col, Tabs, Progress, Stack, Text } from 'rsuite'
import parse from 'html-react-parser'

import AppFrame from 'components/App/AppFrame'
import LessonCards from 'components/Courses/LessonCards'
import PageNotFound from 'components/App/PageNotFound'

import { dayjs, DATE_FORMAT } from 'lib/datetime'

import 'components/Courses/Courses.css'
import { CourseProvider, useCourseContext } from 'components/Courses/CourseContext'
import { useGlobalContext } from 'components/App/GlobalContext'

export default function ViewCourse() {
	return (
		<AppFrame colSpan={24} xxl={15} xl={15} lg={18} md={24} sm={24} xs={24}>
			<CourseProvider>
				<ViewCourseContent />
			</CourseProvider>
		</AppFrame>
	)
}

function ViewCourseContent() {
	const { isMobile } = useGlobalContext()
	const { updateCourse, course } = useCourseContext()

	const { courseid } = useParams()

	useEffect(() => {
		updateCourse(courseid)
	}, [courseid])

	return (
		<Container className='course-view'>
			<Content>
				<h1>{course?.name}</h1>
				<Grid fluid>
					<Row gutter={30}>
						<Col xs={24}>
							<Tabs defaultActiveKey={1}>
								<Tabs.Tab eventKey={1} title='Dashboard'>
									<Stack direction='row' spacing={30}>
										<Stack.Item>
											<Stack direction='column'>
												<Stack.Item>
													<h4>Your Progress</h4>
												</Stack.Item>
												<Stack.Item>
													<div style={{ width: '120px' }}>
														<Progress.Circle
															percent={course?.progress}
															status={course?.progress === 100 ? 'success' : 'active'}
															strokeColor='#ffac2a'
														/>
													</div>
												</Stack.Item>
											</Stack>
										</Stack.Item>
										<Stack.Item>
											<Stack direction='column'>
												<Stack.Item style={{ height: '2em' }} />
												<Stack.Item>
													<div style={{ width: (isMobile ? '100%' : '60%') }}>
														Right now, your recommended target should be around{' '}
														<strong>{course?.cohortProgress}%</strong>
													</div>
												</Stack.Item>
											</Stack>
										</Stack.Item>
									</Stack>
								</Tabs.Tab>
								<Tabs.Tab eventKey={2} title='About'>
									{course?.description && parse(course?.description)}
									<h4>Course Dates</h4>
									<ul>
										<li>
											{dayjs(course?.startdate).format(DATE_FORMAT)} &rarr;{' '}
											{dayjs(course?.enddate).format(DATE_FORMAT)}
										</li>
									</ul>
									<h4>Instructors</h4>
									<ul>
										{course?.instructors?.map(function (v, i) {
											return (
												<li key={i}>
													{v.firstname} {v.lastname}
												</li>
											)
										})}
									</ul>

									<h4>Assistants</h4>
									<ul>
										{course?.assistants?.map(function (v, i) {
											return (
												<li key={i}>
													{v.firstname} {v.lastname}
												</li>
											)
										})}
									</ul>
								</Tabs.Tab>
							</Tabs>
						</Col>
					</Row>
					<Row gutter={30}>
						<Col xs={24}>
							<LessonCards />
						</Col>
					</Row>
				</Grid>
			</Content>
		</Container>
	)
}
