import AdminFrame from 'components/Admin/AdminFrame'

function Dashboard() {
	return (
		<AdminFrame>
			<h1>Dashboard</h1>
		</AdminFrame>
	)
}

export default Dashboard
