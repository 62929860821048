import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
	Container,
	Content,
	Grid,
	Row,
	Col,
	Panel,
	FlexboxGrid,
	IconButton,
	Navbar,
	Nav,
	DatePicker,
	Stack,
} from 'rsuite'
import { TbPlus, TbArrowLeft, TbArrowRight } from 'react-icons/tb'

import AdminFrame from 'components/Admin/AdminFrame'
import LessonSteps from 'components/Courses/LessonSteps'
import AppLink from 'components/App/AppLink'
import Editable from 'components/Admin/Editable'
import EditableDate from 'components/Admin/EditableDate'
import { AppEditor, ADMIN_TOOLBAR } from 'components/App/Editor/AppEditor'

import { fetchapi } from 'lib/fetch'

import 'components/Courses/Lesson.css'
import { CourseProvider, useCourseContext } from 'components/Courses/CourseContext'

export default function EditLesson() {
	return (
		<AdminFrame>
			<CourseProvider>
				<EditLessonContent />
			</CourseProvider>
		</AdminFrame>
	)
}

function EditLessonContent() {
	const { isAdmin, course, courseid, updateCourse, activeLesson, lessonid, updateSteps } =
		useCourseContext()

	const [lessonTitle, setLessonTitle] = useState()
	const [releasedate, setReleasedate] = useState()

	const [prevUrl, setPrevUrl] = useState('#')
	const [prevDisabled, setPrevDisabled] = useState(true)
	const [nextUrl, setNextUrl] = useState('#')
	const [nextDisabled, setNextDisabled] = useState(true)

	useEffect(
		function () {
			setLessonTitle(activeLesson?.title)
			updateSteps(activeLesson?.steps)

			const lessonCount = course?.lessons?.length

			if (activeLesson?.sortorder > 1) {
				setPrevUrl(`/admin/course/${courseid}/${activeLesson?.sortorder - 1}`)
				setPrevDisabled(false)
			} else {
				setPrevUrl('#')
				setPrevDisabled(true)
			}

			if (activeLesson?.sortorder < lessonCount) {
				setNextUrl(`/admin/course/${courseid}/${activeLesson?.sortorder + 1}`)
				setNextDisabled(false)
			} else {
				setNextUrl('#')
				setNextDisabled(true)
			}

			setReleasedate(new Date(activeLesson?.releasedate))
		},
		[activeLesson]
	)

	async function createStep() {
		if (isAdmin) {
			console.log(activeLesson)
			const response = await fetchapi(`/admin/course/${courseid}/${lessonid}/new`)

			if (response?.success) {
				await updateCourse()
			}
		}
	}

	async function updateBody(data) {
		console.log(data)
	}

	return (
		<Container className='lesson-view has-subnav'>
			<Content>
				<Navbar className='subnav'>
					<Nav>
						<Nav.Item
							eventKey='back'
							as={AppLink}
							href={`/admin/course/${courseid}`}
							icon={<TbArrowLeft />}
						>
							Back to Lessons
						</Nav.Item>
					</Nav>
					<Nav pullRight>
						<Nav.Item
							eventKey='prevLesson'
							as={AppLink}
							href={prevUrl}
							disabled={prevDisabled}
							active={false}
						>
							<TbArrowLeft />
							Prev Lesson
						</Nav.Item>
						<Nav.Item
							eventKey='prevLesson'
							as={AppLink}
							href={nextUrl}
							disabled={nextDisabled}
							active={false}
						>
							Next Lesson
							<TbArrowRight />
						</Nav.Item>
					</Nav>
				</Navbar>
				<h1>{course?.name}</h1>
				<Editable
					as='h2'
					defaultValue={lessonTitle}
					field='lessonTitle'
					id={activeLesson?.id}
					placeholder='Enter the lesson title'
					onSave={updateCourse}
				/>
				<Grid fluid>
					<Row gutter={30}>
						<Col lg={12}>
							<LessonSteps onUpdate={updateCourse} />
							<FlexboxGrid justify='center'>
								<IconButton
									circle
									icon={<TbPlus />}
									appearance='ghost'
									size='lg'
									title='Add a Lesson'
									onClick={createStep}
								/>
							</FlexboxGrid>
						</Col>
						<Col lg={12}>
							<Panel bordered>
								<h4>Lesson Settings</h4>
								<Stack className='edit-group' direction='row'>
									<Stack.Item>
										<label style={{ whiteSpace: 'nowrap' }}>Release Date:</label>
									</Stack.Item>
									<Stack.Item>
										<EditableDate
											defaultValue={releasedate}
											field='lessonReleaseDate'
											id={activeLesson?.id}
											placeholder='Select a release date'
											onSave={updateCourse}
										/>
									</Stack.Item>
								</Stack>
								<div>
									<label>Lesson Description:</label>
									<AppEditor
										editable
										data={activeLesson?.body}
										field='lessonBody'
										id={activeLesson?.id}
										toolbar={ADMIN_TOOLBAR}
									/>
								</div>
							</Panel>
						</Col>
					</Row>
				</Grid>
			</Content>
		</Container>
	)
}
