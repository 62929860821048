import CommentNode from 'components/Comments/CommentNode'

function CommentTree({ comments, ...props }) {
	if (comments?.length > 0) {
		return (
			<div className='comment-tree' {...props}>
				<ul>
					{comments.map(function (comment) {
						return <CommentNode key={comment.id} comment={comment} />
					})}
				</ul>
			</div>
		)
	} else {
		return <></>
	}
}
export default CommentTree
