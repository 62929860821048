import { useEffect, useRef, useState } from 'react'
import {
	Container,
	Content,
	Button,
	Input,
	Panel,
	FlexboxGrid,
	Col,
	Stack,
	Grid,
	Row,
	SelectPicker,
	Divider,
	Whisper,
	Tooltip,
	Progress,
	Text,
	Modal,
	useToaster,
	Message
} from 'rsuite'
import { Link } from 'react-router-dom'
import { get } from 'lib/storage'
import { fetchapi } from 'lib/fetch'
import PageNotFound from 'components/App/PageNotFound'
import './Registration.css'
import { useParams } from 'react-router-dom'
import Password from './Password'
import { passwordStrength } from 'check-password-strength'
import { Country, State } from 'country-state-city'
import { getTimezoneByCountry } from "country-timezone-list"
import { pathPrefix } from 'lib/utils'

export default function Registration() {
	const params = useParams()
	const toaster = useToaster()

	function mapCountriesStates(v) {
		return {
			label: v.name,
			value: v.isoCode
		}
	}

	const countriesList = Country.getAllCountries().map(mapCountriesStates)
	const [statesList, setStatesList] = useState([])
	const [timezonesList, setTimezonesList] = useState([])

	const [firstname, setFirstName] = useState('')
	const [lastname, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [city, setCity] = useState('')
	const [timezone, setTimezone] = useState('')

	const [confirmPasswordError, setConfirmPasswordError] = useState(false)
	const [passwordStrengthValue, setPasswordStrengthValue] = useState(0)
	const [passwordStrengthColor, setPasswordStrengthColor] = useState('')

	const [inviteCode, setInviteCode] = useState()
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [pending, setPending] = useState(true)

	const [disabled, setDisabled] = useState(true)
	const [successModal, setSuccessModal] = useState(false)

	const failMessage = (
		<Message showIcon type='error' closeable>
			Something went wrong. Please try again. If the problem persists, send an email to
			<a href='mailto:support@godlovesart.com'>support@godlovesart.com</a>
		</Message>
	)

	async function checkInvite(code) {
		const response = await fetchapi(`/register/${code}/validate`)

		if (response?.success) {
			setEmail(response.email)
		}

		return response?.success
	}

	function validateForm() {
		return (
			firstname &&
			lastname &&
			password &&
			password?.length >= 8 &&
			confirmPassword &&
			password === confirmPassword
		)
	}

	async function createAccount() {
		const response = await fetchapi(`/register/${inviteCode}/complete`, {
			firstname,
			lastname,
			country,
			state,
			city,
			timezone,
			password
		})

		if (response?.success) {
			setSuccessModal(true)
		} else {
			toaster.push(failMessage, { placement: 'bottomCenter', duration: 5000 })
		}
	}

	useEffect(() => {
		const color = {
			0: '#f54336',
			1: '#fa8900',
			2: '#ffca61',
			3: '#37ab3c',
		}
		const result = passwordStrength(password)

		if (password === '') {
			setPasswordStrengthValue(0)
		} else if (result) {
			console.log(result)
			setPasswordStrengthValue((result.id * 25) + 25)
			setPasswordStrengthColor(color[result.id])
		}
	}, [password])

	useEffect(() => {
		if (confirmPassword === '' || password === confirmPassword) {
			setConfirmPasswordError(false)
		} else {
			setConfirmPasswordError(true)
		}
	}, [password, confirmPassword])

	useEffect(() => {
		setDisabled(!validateForm())
	}, [firstname, lastname, password, confirmPassword])

	useEffect(() => {
		; (async () => {
			const profile = await get('Profile')

			if (profile?.id) {
				setIsLoggedIn(true)
			} else {
				const validCode = await checkInvite(params.invite)

				if (validCode) {
					setInviteCode(params.invite)
				}
			}

			setPending(false)
		})()
	}, [params])

	const required = (<Tooltip>Required</Tooltip>)
	const min8 = (<Tooltip>Must be 8 characters or more in length</Tooltip>)

	return (
		pending ? (
			<></>
		) : (
			isLoggedIn || !inviteCode ? (
				<PageNotFound />
			) : (
				<>
					<div className='registration'>
						<Container>
							<Content>
								<FlexboxGrid justify='center'>
									<FlexboxGrid.Item as={Col} colSpan={24} xs={24} sm={24} md={24} lg={18} xl={16}>
										<Panel header={<RegistrationHeader />} bordered>
											<Grid fluid>
												<Row gutter={20}>
													<Col xs={24} sm={24} md={12} lg={12} xl={12}>
														<Stack direction='column' spacing={20}>
															<Stack.Item>
																<h2>Personal Information</h2>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Whisper trigger="focus" speaker={required} placement='top'>
																	<Input onChange={setFirstName} placeholder='First Name' size='lg' />
																</Whisper>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Whisper trigger="focus" speaker={required} placement='top'>
																	<Input onChange={setLastName} placeholder='Last Name' size='lg' />
																</Whisper>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<SelectPicker
																	block
																	data={countriesList}
																	placeholder='Country'
																	onSelect={(value) => {
																		setCountry(value)

																		const states = State.getStatesOfCountry(value).map(mapCountriesStates)
																		setStatesList(states)

																		const tzList = getTimezoneByCountry(value).map((v) => {
																			return {
																				label: v.name.replace('_', ' '),
																				value: v.fullName
																			}
																		})
																		setTimezonesList(tzList)
																	}}
																	onClean={() => setCountry('')}
																	virtualized
																	size='lg'
																/>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<SelectPicker
																	block
																	data={statesList}
																	placeholder='State/Province/Region'
																	onSelect={setState}
																	disabled={!country}
																	size='lg'
																/>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Input placeholder='City/Municipality' onChange={setCity} size='lg' />
																<Divider />
																<SelectPicker
																	block
																	data={timezonesList}
																	placeholder='Preferred Timezone'
																	onSelect={setTimezone}
																	disabled={!country}
																	placement='topStart'
																	size='lg'
																/>
															</Stack.Item>
														</Stack>
													</Col>
													<Col xs={24} sm={24} md={12} lg={12} xl={12}>
														<Stack direction='column' spacing={20}>
															<Stack.Item>
																<h2>Account Information</h2>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Input value={email} disabled placeholder='Email Address' size='lg' />
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Password
																	placeholder='Choose a Password'
																	onChange={setPassword}
																	size='lg'
																	tooltip={min8}
																	tooltipPlacement='top'
																/>
																<Progress.Line
																	showInfo={false}
																	percent={passwordStrengthValue}
																	strokeColor={passwordStrengthColor}
																	style={{ paddingBottom: 0 }}
																/>
																<Text size='sm' align='center'>Password Strength</Text>
															</Stack.Item>
															<Stack.Item alignSelf='stretch'>
																<Password id='confirmEmil' placeholder='Confirm Your Password' onChange={setConfirmPassword} size='lg' />
																<Tooltip visible={confirmPasswordError} arrow={true}>
																	The passwords do not match
																</Tooltip>
															</Stack.Item>
														</Stack>
													</Col>
												</Row>
												<Row gutter={20}>
													<Col xs={24} sm={24} md={24} lg={24} xl={24}>
														<Stack direction='column' spacing={20}>
															<Stack.Item>
																<Text size='sm' style={{ margin: '20px 0' }}>
																	By creating an account and using the God Loves Art App, you agree that:
																	you have obtained the appropriate rights and/or permissions to use the content you upload;
																	you retain rights to your own original content; and God Loves Art may reach out to you to
																	obtain your permission to use your original content (text and/or imagery) in their
																	social media content or other published content. God Loves Art respects
																	your right to your intellectual property and your and privacy.
																</Text>
															</Stack.Item>
															<Stack.Item>
																<Button
																	size='lg'
																	appearance='primary'
																	disabled={disabled}
																	onClick={createAccount}
																>
																	Create My Account
																</Button>
															</Stack.Item>
														</Stack>
													</Col>
												</Row>
											</Grid>
										</Panel>
									</FlexboxGrid.Item>
								</FlexboxGrid>
							</Content>
						</Container>
					</div >
					<Modal open={successModal}>
						<Modal.Header>
							<Modal.Title>Congratulations!</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Text>
								You have successfully completed your account registration.
								Click the button below to sign in.
							</Text>
						</Modal.Body>
						<Modal.Footer>
							<a
								href='/'
								className='rs-btn rs-btn-primary'
								style={{ display: 'block' }}
							>
								Sign In
							</a>
						</Modal.Footer>
					</Modal>
				</>
			)
		)
	)
}

function RegistrationHeader() {
	return (
		<FlexboxGrid justify='center'>
			<FlexboxGrid.Item as={Col} colSpan={24} xs={24} sm={24} md={18} lg={12} xl={12}>
				<img src={`${pathPrefix}/images/logo-color.svg`} />
				<h1>Register a New Account</h1>
			</FlexboxGrid.Item>
		</FlexboxGrid>
	)
}
