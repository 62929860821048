import AppFrame from 'components/App/AppFrame'
import AppLink from 'components/App/AppLink'
import { fetchapi } from 'lib/fetch'
import { useEffect, useState } from 'react'
import { Text, Panel, Stack, Progress, Button } from 'rsuite'
import parse from 'html-react-parser'
import { TbArrowRight } from 'react-icons/tb'
import { useGlobalContext } from 'components/App/GlobalContext'

export default function MyCourses() {
	const [courses, setCourses] = useState([])

	useEffect(() => {
		; (async () => {
			try {
				const courses = await fetchapi('/courses')

				if (courses?.success) {
					setCourses(courses.courses)
				}
			} catch (err) {
				console.error(err)
			}
		})()
	}, [])

	return (
		<AppFrame className='my-courses' colSpan={24} xxl={15} xl={15} lg={18} md={24} sm={24} xs={24}>
			<h1>Courses</h1>
			{courses.length > 0 ? (
				<>
					<h2 className='course-section'>My Enrolled Courses</h2>
					<Stack direction='column'>
						{courses.map((course) =>
							<Stack.Item alignSelf='stretch'>
								<CourseCard key={course.id} course={course} />
							</Stack.Item>
						)}
					</Stack>
				</>
			) : (
				<>
					<Text>Not enrolled in any courses (yet).</Text>
				</>
			)}
		</AppFrame>
	)
}


function CourseCard({ course }) {
	return (
		<>
			<Panel header={<CourseCardHeader course={course} />} bordered>
				{course.description && parse(course.description)}
			</Panel>
		</>
	)
}

function CourseCardHeader({ course }) {
	const {
		isMobile
	} = useGlobalContext()

	return (
		<Stack direction={isMobile ? 'column' : 'row'} spacing={20}>
			<Stack.Item flex={1}>
				<h3>{course.name}</h3>
			</Stack.Item>
			<Stack.Item style={{ width: (isMobile ? '100%' : '60px') }}>
				{isMobile ? (
					<Progress.Line
						percent={course?.progress || 0}
						status={course?.progress === 100 ? 'success' : 'active'}
						strokeColor='#ffac2a'
					/>
				) : (
					<Progress.Circle
						percent={course?.progress || 0}
						status={course?.progress === 100 ? 'success' : 'active'}
						strokeColor='#ffac2a'
					/>
				)}
			</Stack.Item>
			<Stack.Item>
				<AppLink className='course-link' href={`/course/${course.id}`}>
					Enter
				</AppLink>
			</Stack.Item>
		</Stack>
	)
}