import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	Container,
	Content,
	Grid,
	Panel,
	Row,
	Col,
	IconButton,
	FlexboxGrid,
	Navbar,
	Nav,
} from 'rsuite'
import { TbPlus, TbArrowLeft } from 'react-icons/tb'
import parse from 'html-react-parser'
import { ReactSortable } from 'react-sortablejs'

import AdminFrame from 'components/Admin/AdminFrame'
import LessonCards from 'components/Courses/LessonCards'
import Editable from 'components/Admin/Editable'

import { dayjs, DATE_FORMAT } from 'lib/datetime'
import { fetchapi } from 'lib/fetch'

import 'components/Courses/Courses.css'

import AppLink from 'components/App/AppLink'
import { CourseProvider } from 'components/Courses/CourseContext'

export default function EditCourse() {
	return (
		<AdminFrame>
			<CourseProvider>
				<EditCourseContent />
			</CourseProvider>
		</AdminFrame>
	)
}

function EditCourseContent() {
	const { courseid } = useParams()
	const [course, setCourse] = useState()
	const [courseTitle, setCourseTitle] = useState()
	const [lessons, setLessons] = useState([])

	useEffect(function () {
		getLessons()
	}, [])

	async function getLessons() {
		const response = await fetchapi(`/course/${courseid}`)

		setCourse(response?.course || {})
		setCourseTitle(response?.course?.name)
		setLessons(response?.course?.lessons || [])
	}

	async function createLesson() {
		const response = await fetchapi(`/admin/course/${courseid}/new`)

		if (response?.success) {
			await getLessons()
		}
	}

	var localList = []
	async function updateOrder() {
		const order = localList
			.map(function (v, i) {
				return `${v.id}:${i + 1}`
			})
			.join(',')

		await fetchapi(`/admin/course/${courseid}/reorder`, { order })
	}

	function updateList(list) {
		localList = list
		setLessons(list)
	}

	return (
		<Container className='course-view has-subnav'>
			<Content>
				<Navbar className='subnav'>
					<Nav>
						<Nav.Item
							eventKey='back'
							as={AppLink}
							href='/admin/courses'
							icon={<TbArrowLeft />}
						>
							Back to Courses
						</Nav.Item>
					</Nav>
				</Navbar>
				<Editable
					as='h1'
					defaultValue={courseTitle}
					field='courseTitle'
					id={course?.id}
					placeholder='Enter the course title'
				/>
				<Grid fluid>
					<Row gutter={30}>
						<Col lg={12} sm={24}>
							<ReactSortable list={lessons} setList={updateList} onEnd={updateOrder}>
								<LessonCards lessons={lessons} admin onUpdate={getLessons} />
							</ReactSortable>
							<FlexboxGrid justify='center'>
								<IconButton
									circle
									icon={<TbPlus />}
									appearance='ghost'
									size='lg'
									title='Add a Lesson'
									onClick={createLesson}
								/>
							</FlexboxGrid>
						</Col>
						<Col lg={12} sm={24}>
							<Panel bordered>
								<h4>Course Dates</h4>
								<p>
									{dayjs(course?.startdate).format(DATE_FORMAT)} &rarr;{' '}
									{dayjs(course?.enddate).format(DATE_FORMAT)}
								</p>
							</Panel>
							<Panel bordered>{course?.description && parse(course?.description)}</Panel>
							<Panel bordered>
								<h4>Instructors</h4>
								<ul>
									{course?.instructors?.map(function (v, i) {
										return (
											<li key={i}>
												{v.firstname} {v.lastname}
											</li>
										)
									})}
								</ul>

								<h4>Assistants</h4>
								<ul>
									{course?.assistants?.map(function (v, i) {
										return (
											<li key={i}>
												{v.firstname} {v.lastname}
											</li>
										)
									})}
								</ul>
							</Panel>
						</Col>
					</Row>
				</Grid>
			</Content>
		</Container>
	)
}
