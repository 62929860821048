import { useState, createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { dayjs } from 'lib/datetime'
import { fetchapi, clone } from 'lib/fetch'

const PostContext = createContext()

export const usePostContext = () => useContext(PostContext)

export function PostProvider({ initPost, noLinks, children }) {
	const [post, setPost] = useState(null)
	const [postid, setPostid] = useState(null)
	const [postdate, setPostdate] = useState(null)
	const [attachments, setAttachments] = useState([])
	const [saved, setSaved] = useState(false)
	const [comments, setComments] = useState({ count: 0, tree: [] })
	const [refreshUrl, setRefreshUrl] = useState(null)

	const location = useLocation()

	async function fetchPost(postid) {
		const res = await fetchapi(getRefreshUrl())

		if (res.post) {
			initContext(res.post)
		}
	}

	function initContext(post) {
		if (Object.values(post)?.length) {
			setPost(post)
			setPostid(post.postid)
			setPostdate(dayjs(post.postdate).format('lll'))
			setSaved(post.saved)
			setAttachments(clone(post.attachments))
			setComments(clone(post.comments))
		}
	}

	useEffect(() => {
		if (initPost) {
			initContext(initPost)
		}
	}, [initPost])

	return (
		<PostContext.Provider
			value={{
				post,
				setPost,
				fetchPost,
				postid,
				setPostid,
				postdate,
				setPostdate,
				attachments,
				setAttachments,
				saved,
				setSaved,
				comments,
				setComments,
				setRefreshUrl,
				noLinks
			}}
		>
			{children}
		</PostContext.Provider>
	)

	function getRefreshUrl() {
		if (/^\/(admin\/)?course/i.test(location.pathname)) {
			return `/course/post/${initPost.postid}`
		} else if (/^\/post/i.test(location.pathname)) {
			return `/post/${postid}`
		}
	}
}
