import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	ButtonGroup,
	Button,
	Dropdown,
	Text,
	Drawer,
	Panel,
	Modal,
	Stack,
	IconButton,
	Grid,
	Row,
	Col,
	Slider,
	RadioTile,
	RadioTileGroup,
	SelectPicker,
	Divider,
	Toggle,
} from 'rsuite'
import {
	TbCaretDownFilled,
	TbTrash,
	TbCircleCheckFilled,
	TbCircleFilled,
	TbDots,
	TbArrowBigRightFilled,
	TbCopy,
	TbArrowsMove,
	TbArrowLeft,
	TbCheck,
} from 'react-icons/tb'
import { ReactSortable } from 'react-sortablejs'
import parse from 'html-react-parser'

import Editable from 'components/Admin/Editable'
import { AppEditor, ADMIN_TOOLBAR, USER_TOOLBAR } from 'components/App/Editor/AppEditor'

import { fetchapi } from 'lib/fetch'
import { save } from 'lib/edit'
import { useCourseContext } from 'components/Courses/CourseContext'
import DiscussionPost from './DiscussionPost'
import DiscussionFeed from './DiscussionFeed'
import { useGlobalContext } from 'components/App/GlobalContext'

const COMPLETED = 'completed'
const INPROGRESS = 'inprogress'
const START = 'start'
const ADMIN = 'admin'

export default function LessonSteps({ onUpdate }) {
	const { isMobile } = useGlobalContext()
	const { isAdmin, steps, activeStep, gotoStep, updateSteps } = useCourseContext()

	const { courseid, lessonid } = useParams()

	const [complete, setComplete] = useState(activeStep?.completedate !== null)
	const [openDrawer, setOpenDrawer] = useState(false)
	const [discussion, setDiscussion] = useState({
		discussion: null,
		commenparent: null,
	})

	useEffect(() => {
		setComplete(!!activeStep?.completedate)
	}, [activeStep])

	var localList = []
	async function updateOrder() {
		const order = localList
			.map(function (v, i) {
				return `${v.id}:${i + 1}`
			})
			.join(',')

		await fetchapi(`/admin/course/${courseid}/${lessonid}/reorder`, { order })
	}
	function updateList(list) {
		localList = list
		updateSteps(list)
	}

	async function startStep(i) {
		const step = steps[i]

		const status = getStepStatus(step, isAdmin)
		const startdate = step.startdate

		var response = {
			success: true,
		}

		if (!isAdmin && !startdate) {
			response = await fetchapi(`/course/${courseid}/${lessonid}/${i + 1}/start`)

			if (response?.success) {
				updateSteps(response.steps)
				setComplete(!!step.completedate)
			}
		}

		gotoStep(i + 1)
		setOpenDrawer(true)
		setDiscussion({
			discussion: step.discussion,
			commentparent: step.commentparent,
		})

		if (status === START) {
			onUpdate && onUpdate()
		}
	}

	function closeStep() {
		setOpenDrawer(false)
	}

	async function toggleComplete() {
		const response = await fetchapi(
			`/course/${courseid}/${lessonid}/${activeStep?.sortorder}/${complete ? 'incomplete' : 'complete'}`
		)

		if (response?.success) {
			updateSteps(response.steps)
			setComplete(!complete)
			onUpdate && onUpdate()
		}
	}

	function renderSteps() {
		return steps.map(function (v, i) {
			const status = getStepStatus(v, isAdmin)

			return (
				<Panel key={v.id} className={`step-card step-${status}`} bordered>
					<Grid fluid>
						<Row>
							<Col xs={24}>
								<Stack direction={isMobile ? 'column' : 'row'} alignItems='flex-start'>
									<Stack.Item flex={1}>
										{isAdmin ? (
											<Editable
												as='div'
												className='step-title editable'
												defaultValue={v.title}
												field='stepTitle'
												id={v.id}
												placeholder='Enter the step title'
												onSave={onUpdate}
											/>
										) : (
											<h3 className='step-title'>{v.title}</h3>
										)}
									</Stack.Item>
									<Stack.Item>
										<LessonStepButton
											step={v}
											className='step-status'
											onClick={() => startStep(i)}
											isAdmin={isAdmin}
											onUpdate={onUpdate}
										/>
									</Stack.Item>
								</Stack>
							</Col>
						</Row>
						<Row>
							<Col xs={24}>
								<Stack direction='row' alignItems='flex-start'>
									<Stack.Item>
										<label style={{ padding: '7px 0', marginRight: '10px' }}>
											Points:
										</label>
									</Stack.Item>
									<Stack.Item flex={1}>
										{isAdmin ? (
											<Editable
												defaultValue={v.points}
												field='stepPoints'
												id={v.id}
												placeholder='0'
												onSave={onUpdate}
											>
												{(props, ref) => {
													return (
														<Slider
															{...props}
															ref={ref}
															graduated
															progress
															min={0}
															max={75}
															step={5}
															renderMark={(mark) => {
																return mark
															}}
														/>
													)
												}}
											</Editable>
										) : (
											<Text className='step-points' style={{ padding: '7px 0' }}>
												{v.points}
											</Text>
										)}
									</Stack.Item>
								</Stack>
							</Col>
						</Row>
					</Grid>
				</Panel>
			)
		})
	}

	return (
		<>
			{isAdmin ? (
				<ReactSortable list={steps} setList={updateList} onEnd={updateOrder}>
					{renderSteps()}
				</ReactSortable>
			) : (
				renderSteps()
			)}
			<Drawer
				className='step-panel'
				open={openDrawer}
				placement='right'
				onClose={closeStep}
				size='full'
			>
				<Drawer.Header>
					{!isMobile && <Drawer.Title>{activeStep?.title}</Drawer.Title>}
					{!isAdmin && (
						<Drawer.Actions>
							<Button className={complete ? 'complete' : 'not-complete'} appearance='primary' onClick={toggleComplete}>
								Mark {complete && 'NOT '}Complete
							</Button>
						</Drawer.Actions>
					)}
				</Drawer.Header>
				<Drawer.Body>
					<Grid fluid>
						<Row gutter={30}>
							<Col xsHidden smHidden mdHidden lg={4} xl={4} xxl={4}>
								{steps.map((v, i) => {
									const status = getStepStatus(v, isAdmin)
									const classes = ['step-nav-btn', `step-${status}`]
									var icon

									switch (status) {
										case ADMIN:
											icon = <TbCircleFilled />
											break
										case INPROGRESS:
											icon = <TbDots />
											break
										case COMPLETED:
											icon = <TbCircleCheckFilled />
											break
										default:
											icon = <TbArrowBigRightFilled />
									}

									if (v.sortorder === activeStep?.sortorder) {
										classes.push('step-current')
									}

									return (
										<IconButton
											key={i}
											className={classes.join(' ')}
											icon={icon}
											title={v.title}
											onClick={() => startStep(i)}
										>
											{v.title}
										</IconButton>
									)
								})}
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
								<h3 style={{ marginBottom: '1em' }}>{activeStep?.title}</h3>
								{isAdmin ? (
									<>
										<AppEditor
											editable
											data={activeStep?.body}
											height='fluid'
											field='stepBody'
											id={activeStep?.id}
											toolbar={isAdmin ? ADMIN_TOOLBAR : USER_TOOLBAR}
										/>
										<DiscussionOptions
											stepId={activeStep?.id}
											steps={steps}
											discussion={discussion}
											onUpdate={onUpdate}
										/>
									</>
								) : (
									<>
										{parse(activeStep?.body || '')}
										{activeStep?.body && <Divider />}
										{activeStep?.discussion === 'discussion' && (
											<DiscussionPost className='discussion-post' />
										)}
										{activeStep?.discussion === 'comment' &&
											activeStep?.commentparent && (
												<div className='discussion-post'>
													<DiscussionFeed id={activeStep?.commentparent} />
												</div>
											)}
									</>
								)}
							</Col>
						</Row>
					</Grid>
				</Drawer.Body>
			</Drawer>
		</>
	)
}

function LessonStepButton({ step, isAdmin, className, onUpdate, ...rest }) {
	const { updateCourse } = useCourseContext()

	const [moveDialog, setMoveDialog] = useState(false)
	const [courseLessons, setCourseLessons] = useState([])
	const [moveStepTo, setMoveStepTo] = useState('')

	const [deleteDialog, setDeleteDialog] = useState(false)

	const status = getStepStatus(step, isAdmin)
	var cta = ''

	async function copyStep() {
		try {
			const response = await fetchapi(`/admin/step/${step.id}/copy`)

			if (response?.success) {
				onUpdate && onUpdate()
			}
		} catch (err) {
			console.log(err)
		}
	}

	async function getCourseLessons() {
		try {
			const response = await fetchapi(`/admin/step/${step.id}/move/options`)

			if (response?.success) {
				setCourseLessons(response.lessons)
				setMoveDialog(true)
			}
		} catch (err) {
			console.log(err)
		}
	}

	async function moveStep() {
		try {
			const response = await fetchapi(`/admin/step/${step.id}/move/${moveStepTo}`)

			if (response?.success) {
				setMoveDialog(false)
				onUpdate && onUpdate()
			}
		} catch (err) {
			console.log(err)
		}
	}

	async function deleteStep() {
		try {
			const response = await fetchapi(`/admin/step/${step.id}/delete`)

			if (response?.success) {
				updateCourse()
				setDeleteDialog(false)
			}
		} catch (err) {
			console.log(err)
		}
	}

	switch (status) {
		case ADMIN:
			cta = 'View/Edit'
			break
		case INPROGRESS:
			cta = 'In Progress'
			break
		case COMPLETED:
			cta = 'Completed'
			break
		default:
			cta = 'Start'
	}

	return (
		<>
			<ButtonGroup className={isAdmin ? 'admin-split-btn-group' : ''}>
				<Button className={className} {...rest}>
					{cta}
				</Button>
				{isAdmin ? (
					<Dropdown
						className='admin-split-btn'
						icon={<TbCaretDownFilled />}
						noCaret
						placement='bottomEnd'
					>
						<Dropdown.Item onSelect={copyStep}>
							<Text>
								<TbCopy />
								Duplicate Step
							</Text>
						</Dropdown.Item>
						<Dropdown.Item onSelect={getCourseLessons}>
							<Text>
								<TbArrowsMove />
								Move Step to Lesson
							</Text>
						</Dropdown.Item>
						<Dropdown.Separator />
						<Dropdown.Item onSelect={() => setDeleteDialog(true)}>
							<Text color='red'>
								<TbTrash />
								Delete
							</Text>
						</Dropdown.Item>
					</Dropdown>
				) : (
					<></>
				)}
			</ButtonGroup>
			{isAdmin && (
				<>
					<Modal
						size='sm'
						backdrop
						open={moveDialog}
						onClose={() => setMoveDialog(false)}
					>
						<Modal.Header>
							<Modal.Title>Move This Step to Lesson:</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<RadioTileGroup aria-label='Select a Lesson' onChange={setMoveStepTo}>
								{courseLessons.map((lesson) => (
									<RadioTile label={lesson.title} value={lesson.id} />
								))}
							</RadioTileGroup>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => setMoveDialog(false)} appearance='subtle'>
								Cancel
							</Button>
							<Button onClick={moveStep} appearance='primary'>
								Move
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal
						size='xs'
						backdrop
						open={deleteDialog}
						onClose={() => setDeleteDialog(false)}
					>
						<Modal.Header>
							<Modal.Title>Delete Step?</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete step:
							<Panel bordered style={{ borderColor: 'red' }}>
								<strong>{step.title}</strong>
							</Panel>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => setDeleteDialog(false)} appearance='subtle'>
								Cancel
							</Button>
							<Button onClick={() => deleteStep()} appearance='primary' color='red'>
								DELETE
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</>
	)
}

function DiscussionOptions({ stepId, discussion, onUpdate }) {
	const { steps, activeStep, updateCourse } = useCourseContext()

	const [optionSelect, setOptionSelect] = useState()
	const [stepSelect, setStepSelect] = useState()
	const [stepList, setStepList] = useState([])

	const options = [
		{
			label: 'None',
			value: 'none',
		},
		{
			label: 'Discuss',
			value: 'discussion',
		},
		{
			label: 'Comment',
			value: 'comment',
		},
	]

	useEffect(() => {
		setStepList(
			steps.map((v) => {
				return {
					label: v.title,
					value: v.id,
				}
			})
		)
	}, [])

	useEffect(() => {
		setOptionSelect(activeStep.discussion || 'none')
		setStepSelect(activeStep.commentparent || '')
	}, [activeStep])

	async function selectOption(value) {
		setOptionSelect(value)

		const response = await save('discussion', stepId, value)

		if (response?.success) {
			if (value === 'none' || value === 'discussion') {
				setStepSelect('')
			} else if (value === 'comments') {
				onUpdate && onUpdate()
			}

			updateCourse()
		} else {
			setOptionSelect(activeStep.discussion)
		}
	}

	async function selectStep(value) {
		setStepSelect(value)

		const response = await save('discussionComments', stepId, value)

		if (!response?.success) {
			setStepSelect(activeStep.commentparent)
		}
	}

	return (
		<Panel bordered header={<h5>Discussion Options</h5>}>
			<Stack spacing={10} direction='row' justifyContent='flex-start'>
				<SelectPicker
					data={options}
					value={optionSelect}
					defaultValue={discussion.discussion || 'none'}
					onSelect={selectOption}
					searchable={false}
					preventOverflow
				/>
				<SelectPicker
					data={stepList}
					value={stepSelect}
					defaultValue={discussion.commentparent || ''}
					onSelect={selectStep}
					searchable={false}
					disabled={optionSelect !== 'comment'}
					label='On Step'
					placement='bottomEnd'
					preventOverflow
				/>
			</Stack>
		</Panel>
	)
}

function getStepStatus(step, isAdmin) {
	if (isAdmin) {
		return ADMIN
	}

	if (step.startdate && step.completedate) {
		return COMPLETED
	} else if (step.startdate && !step.completedate) {
		return INPROGRESS
	} else {
		return START
	}
}
