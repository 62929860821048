import { useGlobalContext } from "../GlobalContext"
import { Nav, Col } from 'rsuite'
import { useMatch } from 'react-router-dom'
import {
	TbHome,
	TbEye,
	TbWorld,
	TbBook2,
	TbHeadphonesFilled,
	TbTicket,
} from 'react-icons/tb'
import AppLink from 'components/App/AppLink'
import { RxHeartFilled } from 'react-icons/rx'
import { openBrowser } from "lib/utils"

import './MobileNav.css'

export default function MobileNav() {
	const {
		settings,
		profile
	} = useGlobalContext()

	return (
		<Col className='mobile-nav' lgHidden xlHidden xxlHidden>
			<Nav
				appearance='subtle'
			>
				<Nav.Item
					eventKey='home'
					icon={<TbHome size={30} />}
					href='/'
					active={useMatch('/')}
					as={AppLink}
				>
					Home
				</Nav.Item>
				{settings?.content_doodle?.value && (<Nav.Item href='#' as={AppLink} icon={<TbHeadphonesFilled size={30} />}>
					Doodle Devotionals
				</Nav.Item>)}
				{settings?.content_events?.value && (<Nav.Item href='#' as={AppLink} icon={<TbTicket size={30} />}>
					Events
				</Nav.Item>)}
				<Nav.Item
					as={AppLink}
					icon={<TbEye size={30} />}
					onClick={() => {
						openBrowser('https://godlovesart.com/exhibits/', '_blank')
					}}
				>
					Exhibits
				</Nav.Item>
				{settings?.content_ilaj?.value && (<Nav.Item href='#' as={AppLink} icon={<RxHeartFilled size={30} />}>
					ILA Journal
				</Nav.Item>)}
				<Nav.Item
					href='/courses'
					as={AppLink}
					eventKey='courses'
					title='Courses'
					icon={<TbBook2 size={30} />}
				>
					Courses
				</Nav.Item>
				{profile.isEmerge && settings?.content_emerge?.value && (
					<Nav.Item
						href='/emerge'
						as={AppLink}
						eventKey='groups'
						title='Emerge'
						icon={<TbWorld size={30} />}
					>
						Emerge
					</Nav.Item>
				)}
			</Nav>
		</Col>
	)
}