import { fetchapi } from 'lib/fetch'
import { Dropdown, IconButton } from 'rsuite'
import { TbDots, TbPencil, TbTrash } from 'react-icons/tb'
import { useGlobalContext } from 'components/App/GlobalContext'

function CardOptions({ postid, ownerid, onDelete }) {
	const {
		profile
	} = useGlobalContext()

	async function moveToTrash() {
		const res = await fetchapi(`/post/${postid}/trash`)

		if (res.success) {
			onDelete && onDelete(true)
		}
	}

	function iconButton(props) {
		return (
			<IconButton {...props} icon={<TbDots />} className='card-options-btn card-btn' />
		)
	}

	if (profile.id === ownerid) {
		return (
			<Dropdown title='Post Options' renderToggle={iconButton} placement='bottomEnd'>
				<Dropdown.Item icon={<TbPencil />}>Edit Post</Dropdown.Item>
				<Dropdown.Item divider />
				<Dropdown.Item icon={<TbTrash />} onClick={moveToTrash}>
					Delete Post
				</Dropdown.Item>
			</Dropdown>
		)
	} else {
		return <></>
	}
}

export default CardOptions
