import { fetchapi } from 'lib/fetch'
import { useState } from 'react'
import { Button, Modal, Input, Stack, Text, DateRangePicker } from 'rsuite'
import { AppEditor } from 'components/App/Editor/AppEditor'
import { DATE_TIME } from 'lib/datetime'

export default function CreateEvent() {
	const [title, setTitle] = useState()
	const [description, setDescription] = useState()
	const [startdate, setStartdate] = useState()
	const [enddate, setEnddate] = useState()
	const [isAllDay, setIsAllDay] = useState(false)
	const [open, setOpen] = useState(false)

	function toggleDialog() {
		setOpen(!open)
	}

	function createEvent() {

	}

	return (
		<>
			<Button
				type='button'
				appearance='primary'
				onClick={toggleDialog}
			>
				Create Event
			</Button>
			<Modal open={open}>
				<Modal.Header>
					Create an Event
				</Modal.Header>
				<Modal.Body>
					<Stack spacing={30} direction='column'>
						<Stack.Item alignSelf='stretch'>
							<Input
								placeholder='Event Title'
								onChange={setTitle}
							/>
						</Stack.Item>
						<Stack.Item alignSelf='stretch'>
							<Text style={{ marginBottom: '10px' }}>Event Description</Text>
							<AppEditor onChange={setDescription} />
						</Stack.Item>
						<Stack.Item alignSelf='stretch'>
							<DateRangePicker
								block
								format={DATE_TIME}
								size='large'
								placeholder='Select Start and End Date/Time'
							/>
						</Stack.Item>
					</Stack>
				</Modal.Body>
				<Modal.Footer>
					<Button appearance='subtle' onClick={toggleDialog}>
						Cancel
					</Button>
					<Button appearance='primary' onClick={createEvent}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}