import { useEffect, useState } from 'react'
import EventsBus from 'lib/eventsBus'
import InfiniteScroll from 'react-infinite-scroll-component'
import FeedCard from 'components/Feed/FeedCard'
import { PostProvider } from 'components/Post/PostContext'
import { fetchapi } from 'lib/fetch'
import LoadingCard from 'components/Card/LoadingCard'
import { Text } from 'rsuite'

function Feed({ profileid }) {
	const [feed, setFeed] = useState([])
	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(false)
	const [loaded, setLoaded] = useState(false)

	async function refreshFeed(reset) {
		const response = await fetchapi('/feed' + (profileid ? `/${profileid}` : ''), {
			page,
		})
		const newItems = response.feed.map(function (item) {
			return <FeedCard key={item.postid} item={item} />
		})

		setPage(page + 1)
		setHasMore(response.hasMore)

		if (reset) {
			setFeed(newItems)
		} else {
			setFeed([...feed, newItems])
		}

		setLoaded(true)
		return newItems
	}

	useEffect(
		function () {
			refreshFeed(true)
			EventsBus.on('createPost', function () {
				refreshFeed(true)
			})
		},
		[profileid]
	)

	if (!loaded && feed?.length === 0) {
		return <LoadingCard count={10} />
	} else if (loaded && feed?.length === 0) {
		return (
			<Text align='center'>No posts in the feed (yet).</Text>
		)
	} else {
		return (
			<InfiniteScroll
				dataLength={feed.length}
				next={refreshFeed}
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				pullDownToRefresh={true}
				pullDownToRefreshContent={
					<div className='refresh-pull'>Pull down to refresh</div>
				}
				releaseToRefreshContent={
					<div className='refresh-release'>Release to refresh</div>
				}
				refreshFunction={function () {
					return refreshFeed(true)
				}}
				endMessage={<div className='scroll-end' style={{ marginBottom: '30px' }}>That's all there is for now.</div>}
			>
				{feed}
			</InfiniteScroll>
		)
	}
}

export default Feed
