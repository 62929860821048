import { Container, Table, Button, Stack } from 'rsuite'
import AdminFrame from '../AdminFrame'
import { useState } from 'react'
import { TbCalendar } from 'react-icons/tb'
import CreateEvent from './CreateEvent'

const { Column, HeaderCell, Cell } = Table

export default function Events() {
	const [events, setEvents] = useState([])

	return (
		<AdminFrame>
			<Container>
				<h1>Events</h1>
				<Stack>
					<Stack.Item>
						<CreateEvent />
					</Stack.Item>
				</Stack>
				<Table
					data={events}
					autoHeight={true}
				>
					<Column flexGrow={1}>
						<HeaderCell>Title</HeaderCell>
						<Cell dataKey='title' />
					</Column>
				</Table>
			</Container>
		</AdminFrame>
	)
}