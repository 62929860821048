import { useEffect, useState } from 'react'
import { Animation, Button, Stack, Text } from 'rsuite'
import { TbSend, TbEye, TbEyeClosed } from 'react-icons/tb'
import parse from 'html-react-parser'
import CommentSubtree from 'components/Comments/CommentSubtree'
import { AppEditor } from 'components/App/Editor/AppEditor'
import { dayjs, DATE_TIME } from 'lib/datetime'
import { fetchapi } from 'lib/fetch'
import { usePostContext } from 'components/Post/PostContext'
import { zhCN } from 'rsuite/esm/locales'

function CommentNode({ comment }) {
	const { postid, fetchPost } = usePostContext()

	const [showCommentBox, setShowCommentBox] = useState(false)
	const [showComments, setShowComments] = useState(false)
	const [body, setBody] = useState('')

	function toggleComments() {
		setShowComments(!showComments)
	}

	async function onSubmit() {
		const response = await fetchapi(`/comment/create`, {
			postid,
			parentid: comment.id,
			body,
		})

		if (response.success) {
			onCancel()
			setShowComments(true)
			fetchPost(postid)
		}
	}

	function onCancel() {
		setBody('')
		setShowCommentBox(false)
	}

	return (
		<li>
			<div style={{ paddingBottom: '15px' }}>
				<div className='comment-node-container'>
					<div className='comment-header'>
						<Text as='span' className='comment-owner' size={14}>
							<a
								href={`/user/${comment.ownerid}`}
							>{`${comment.firstname} ${comment.lastname}`}</a>
						</Text>
						<Text as='span' className='comment-header-separator' size={14}>
							&bull;
						</Text>
						<Text
							as='span'
							className='comment-date'
							title={dayjs(comment.postdate).format(DATE_TIME)}
							size={14}
						>
							{dayjs(comment.postdate).fromNow()}
						</Text>
					</div>
					<div className='comment-body'>{comment.body && parse(comment.body)}</div>
					<div className='comment-footer'>
						<Stack className='interactions' direction='row'>
							<Stack.Item>
								{comment.children?.length > 0 && (
									<Button
										appearance='ghost'
										size='xs'
										onClick={toggleComments}
										style={{ marginRight: '10px' }}
									>
										{showComments ? (
											<Text>Hide Comments</Text>
										) : (
											<Text>
												Show {comment.children.length} comment
												{comment.children.length > 1 && 's'}
											</Text>
										)}
									</Button>
								)}
								<Button
									appearance='primary'
									size='xs'
									onClick={() => setShowCommentBox(true)}
								>
									Reply to this comment
								</Button>
							</Stack.Item>
						</Stack>
						{showCommentBox && (
							<Stack direction='column' spacing={10} style={{ marginTop: '10px' }}>
								<Stack.Item alignSelf='stretch'>
									<AppEditor
										data={body}
										onChange={setBody}
										style={{ backgroundColor: '#f5f5f5' }}
									/>
								</Stack.Item>
								<Stack.Item alignSelf='flex-end'>
									<Stack spacing={10}>
										<Button appearance='subtle' onClick={onCancel}>
											Cancel
										</Button>
										<Button appearance='primary' onClick={onSubmit}>
											<TbSend size={20} style={{ marginRight: '5px' }} />
											Comment
										</Button>
									</Stack>
								</Stack.Item>
							</Stack>
						)}
					</div>
				</div>
				{comment.children?.length > 0 && (
					<Animation.Collapse in={showComments} dimension='height'>
						<div style={{ paddingTop: '15px' }}>
							<CommentSubtree comments={comment.children} />
						</div>
					</Animation.Collapse>
				)}
			</div>
		</li>
	)
}
export default CommentNode
