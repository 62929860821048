import { Nav, Navbar, ButtonToolbar, Col } from 'rsuite'

import Notifications from 'components/App/Header/Notifications'
import UserMenu from 'components/App/Header/UserMenu'
import AdminMenu from 'components/Admin/AdminMenu'
import CreatePost from 'components/Post/CreatePost'
import { pathPrefix, isNative } from 'lib/utils'
import './Header.css'

function Header() {
	return (
		<>
			<header>
				<Navbar>
					<Navbar.Brand>
						<Col xsHidden smHidden mdHidden>
							<img src={`${pathPrefix}/images/logo-header.svg`} alt='God Loves Art - Logo' />
						</Col>
						<Col lgHidden xlHidden xxlHidden>
							<img src={`${pathPrefix}/images/gla-heart.svg`} alt='God Loves Art - Logo' />
						</Col>
					</Navbar.Brand>
					<Nav pullRight>
						<ButtonToolbar className='utility'>
							<CreatePost />
							<Notifications />
							<UserMenu />
							<AdminMenu />
						</ButtonToolbar>
					</Nav>
				</Navbar>
			</header>
		</>
	)
}

export default Header
