import { save } from 'lib/edit'
import AdminFrame from '../AdminFrame'
import { Tabs, Panel, Row, Col, Affix, List, Stack, Toggle, Grid, FlexboxGrid } from 'rsuite'
import { useEffect, useState } from 'react'
import { fetchapi } from 'lib/fetch'
import Editable from '../Editable'
import EmailTemplates from './EmailTemplates'

export default function Settings() {
	const [settings, setSettings] = useState({})

	const [emailVars, setEmailVars] = useState([
		{
			name: 'firstname',
			description: `The recipient's first name`,
		},
		{
			name: 'lastname',
			description: `The recipient's last name`,
		},
		{
			name: 'email',
			description: `The recipient's email address`,
		},
		{
			name: 'invite',
			description: `Unique invitation code`,
		}
	])

	useEffect(() => {
		; (async () => {
			let response = await fetchapi('/admin/settings')

			if (response) {
				setSettings(response.settings)
			}
		})()
	}, [])

	return (
		<AdminFrame>
			<Tabs defaultActiveKey="content" vertical appearance="subtle">
				<Tabs.Tab eventKey="content" title="Features">
					<h2>Features</h2>
					<FlexboxGrid justify='start'>
						<FlexboxGrid.Item as={Col} colSpan={24} xs={24} sm={24} md={20} lg={16} xl={12}>
							<List hover>
								{Object.keys(settings).filter((key) => /^content_/.test(key)).map((key, i) => {
									return (
										<List.Item key={i} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
											<Stack direction='row' spacing={20}>
												<Stack.Item flex={1}>
													{settings[key].displayname}
												</Stack.Item>
												<Stack.Item>
													<Toggle
														defaultChecked={settings[key].value}
														onChange={(value) => save(settings[key].save, settings[key].id, value ? 1 : 0)}
													/>
												</Stack.Item>
											</Stack>
										</List.Item>
									)
								})}
							</List>
						</FlexboxGrid.Item>
					</FlexboxGrid>

				</Tabs.Tab>
				<Tabs.Tab eventKey="email" title="Email Templates">
					<Grid fluid>
						<Row>
							<Col xs={24} sm={24} md={18} lg={18} xl={18}>
								<h2>Email Templates</h2>
								<EmailTemplates />
							</Col>
							<Col xsHidden smHidden md={6} lg={6} xl={6}>
								<Affix top={15}>
									<Panel header='Email Variables'>
										<List bordered>
											{emailVars.map((v, i) =>
												<List.Item key={i}>
													<pre>{`{{${v.name}}}`}</pre>
													<p>{v.description}</p>
												</List.Item>
											)}
										</List>
									</Panel>
								</Affix>
							</Col>
						</Row>
					</Grid>
				</Tabs.Tab>
			</Tabs>
		</AdminFrame>
	)
}