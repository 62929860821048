import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Sidebar, Sidenav, Nav } from 'rsuite'
import { TbArrowLeft, TbUsers, TbGauge, TbBook2, TbSettings, TbCalendar } from 'react-icons/tb'

import AppLink from 'components/App/AppLink'

function AdminSidebar() {
	const location = useLocation()
	const [openKeys, setOpenKeys] = useState([])

	useEffect(
		function () {
			if (/^\/admin\/users/.test(location.pathname)) {
				setOpenKeys(['group-users'])
			} else {
				setOpenKeys([])
			}
		},
		[location]
	)

	return (
		<Sidebar style={{ display: 'flex', flexDirection: 'column' }} width={240}>
			<Sidenav expanded={true} defaultOpenKeys={openKeys}>
				<Sidenav.Body>
					<Nav activeKey={location.pathname}>
						<Nav.Item eventKey='/' icon={<TbArrowLeft />} href='/' as={AppLink}>
							Back to App
						</Nav.Item>
						<Nav.Item eventKey='/admin' icon={<TbGauge />} href='/admin' as={AppLink}>
							Dashboard
						</Nav.Item>
						<Nav.Item eventKey='/admin' icon={<TbSettings />} href='/admin/settings' as={AppLink}>
							Settings
						</Nav.Item>
						<Nav.Item
							eventKey='/admin/users'
							icon={<TbUsers />}
							href='/admin/users'
							as={AppLink}
						>
							Users
						</Nav.Item>
						<Nav.Item
							eventKey='/admin/courses'
							icon={<TbBook2 />}
							href='/admin/courses'
							as={AppLink}
						>
							Courses
						</Nav.Item>
						<Nav.Item
							eventKey='/admin/events'
							icon={<TbCalendar />}
							href='/admin/events'
							as={AppLink}
						>
							Events
						</Nav.Item>
					</Nav>
				</Sidenav.Body>
			</Sidenav>
		</Sidebar >
	)
}

export default AdminSidebar
