import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AppFrame from 'components/App/AppFrame'
import './PageNotFound.css'
import { FlexboxGrid, Stack } from 'rsuite'
import { get } from 'lib/storage'

export default function PageNotFound() {
	const [isLoggedIn, setIsLoggedIn] = useState(false)

	useEffect(() => {
		; (async () => {
			const profile = await get('Profile')

			if (profile?.id) {
				setIsLoggedIn(true)
			}
		})()
	}, [])

	return isLoggedIn ? (
		<AppFrame colSpan={24} xxl={15} xl={15} lg={18} md={24} sm={24} xs={24}>
			<NotFoundContent isLoggedIn={isLoggedIn} />
		</AppFrame>
	) : (
		<NotFoundContent isLoggedIn={isLoggedIn} />
	)
}

function NotFoundContent({ isLoggedIn }) {
	return (
		<FlexboxGrid justify='center' align='middle' style={{ height: 'calc(100vh - 60px)' }}>
			<FlexboxGrid.Item className='page-not-found'>
				<Stack direction='column' spacing={30}>
					<Stack.Item>
						<h1>404</h1>
					</Stack.Item>
					<Stack.Item>
						<h2>Page Not Found</h2>
					</Stack.Item>
					{isLoggedIn &&
						<Stack.Item>
							<p>
								<Link to='/'>Go back to the beginning</Link>
							</p>
						</Stack.Item>
					}
				</Stack>
			</FlexboxGrid.Item>
		</FlexboxGrid>
	)
}