import { fetchapi } from 'lib/fetch'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom/dist'
import { Drawer, Table, Tabs, Text } from 'rsuite'
import { dayjs, DATE_FORMAT } from 'lib/datetime'
import CourseFeed from './CourseFeed'

const { Column, HeaderCell, Cell } = Table

export default function Coursework({ userid, courseid, open, onClose, title }) {
	const [coursework, setCoursework] = useState()

	useEffect(() => {
		; (async () => {
			if (courseid && userid && open) {
				const response = await fetchapi(`/admin/course/${courseid}/${userid}/work`)

				if (response?.success) {
					setCoursework(response.coursework)
				}
			}
		})()
	}, [userid, courseid, open])

	return (
		<Drawer
			open={open}
			onClose={onClose}
			placement='right'
			size='full'
			style={{ maxWidth: '1024px' }}
		>
			<Drawer.Header>
				<Drawer.Title>{title}</Drawer.Title>
			</Drawer.Header>
			<Drawer.Body style={{ backgroundColor: '#f5f5f5' }}>
				<Tabs
					defaultActiveKey={1}
				>
					<Tabs.Tab eventKey={1} title='Gradebook'>
						{coursework?.map((lesson) => {
							return (
								<>
									<Text weight='semibold' size='xl'>{lesson.title}</Text>
									<Text>Release Date: {dayjs(lesson.releasedate).format(DATE_FORMAT)}</Text>
									<Table
										data={lesson.steps}
										autoHeight
										style={{
											marginTop: '0',
											marginBottom: '50px'
										}}
									>
										<Column flexGrow={3}>
											<HeaderCell></HeaderCell>
											<Cell dataKey='title' />
										</Column>
										<Column flexGrow={1}>
											<HeaderCell>Start</HeaderCell>
											<Cell>
												{(rowData) => (rowData.startdate && dayjs(rowData.startdate).format(DATE_FORMAT)) || '—'}
											</Cell>
										</Column>
										<Column flexGrow={1}>
											<HeaderCell>Complete</HeaderCell>
											<Cell>
												{(rowData) => (rowData.completedate && dayjs(rowData.completedate).format(DATE_FORMAT)) || '—'}
											</Cell>
										</Column>
										<Column flexGrow={1}>
											<HeaderCell>Points</HeaderCell>
											<Cell>
												{(rowData) => (rowData.completedate && rowData.points) || '—'}
											</Cell>
										</Column>
									</Table>
								</>
							)
						})}
					</Tabs.Tab>
					<Tabs.Tab eventKey={2} title='Feed'>
						<CourseFeed userid={userid} courseid={courseid} />
					</Tabs.Tab>
				</Tabs>
			</Drawer.Body>
		</Drawer>
	)
}