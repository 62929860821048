import { useState } from 'react'
import { Table, Input, Button, IconButton, Panel, Form } from 'rsuite'
import { TbTrash } from 'react-icons/tb'

import AdminFrame from 'components/Admin/AdminFrame'

const { Column, HeaderCell, Cell } = Table

function renderEmpty() {
	return <p style={{ textAlign: 'center', margin: '5px' }}>Nothing yet!</p>
}

function InviteUsers() {
	const [users, setUsers] = useState([])
	const [firstname, setFirstname] = useState('')
	const [lastname, setLastname] = useState('')
	const [email, setEmail] = useState('')

	function updateFirstname(v) {
		setFirstname(v)
	}
	function updateLastname(v) {
		setLastname(v)
	}
	function updateEmail(v) {
		setEmail(v)
	}

	function addUser() {
		setUsers(users.concat({ firstname, lastname, email }))

		setFirstname('')
		setLastname('')
		setEmail('')
	}

	function ActionCell({ rowData, dataKey, onClick, ...props }) {
		return (
			<Cell {...props} style={{ padding: '6px' }} dataKey={dataKey}>
				<IconButton
					icon={<TbTrash />}
					onClick={function () {
						onClick(rowData.id)
					}}
				/>
			</Cell>
		)
	}

	function removeUser(id) {
		const newUsers = Object.assign([], users)

		setUsers(
			newUsers.filter(function (v) {
				return v.id !== id
			})
		)
	}

	function editUser(id, key, value) {
		const newUsers = Object.assign([], users)

		newUsers.find(function (item) {
			return item.id === id
		})[key] = value

		setUsers(newUsers)
	}

	return (
		<AdminFrame>
			<h1>Invite Users</h1>
			<Panel
				bordered
				style={{ margin: '30px 0', padding: '15px', backgroundColor: '#ffffff' }}
			>
				<Form>
					<label>First Name</label>
					<Input type='text' onChange={updateFirstname} value={firstname} />
					<label>Last Name</label>
					<Input type='text' onChange={updateLastname} value={lastname} />
					<label>Email</label>
					<Input type='email' onChange={updateEmail} value={email} />
					<Button onClick={addUser}>Add User</Button>
				</Form>
			</Panel>
			<Table data={users} renderEmpty={renderEmpty}>
				<Column flexGrow={2}>
					<HeaderCell>First Name</HeaderCell>
					<Cell dataKey='firstname' onChange={editUser} />
				</Column>
				<Column flexGrow={2}>
					<HeaderCell>Last Name</HeaderCell>
					<Cell dataKey='lastname' onChange={editUser} />
				</Column>
				<Column flexGrow={2}>
					<HeaderCell>Email</HeaderCell>
					<Cell dataKey='email' onChange={editUser} />
				</Column>
				<Column flexGrow={1}>
					<HeaderCell></HeaderCell>
					<ActionCell dataKey='id' onClick={removeUser} />
				</Column>
			</Table>
		</AdminFrame>
	)
}

export default InviteUsers
