import { useState, useEffect } from 'react'
import {
	Form,
	Input,
	InputGroup,
	Container,
	Row,
	Col,
	TagPicker,
	Button,
	Modal,
} from 'rsuite'
import { fetchapi } from 'lib/fetch'

import { AppEditor } from 'components/App/Editor/AppEditor'

const slugify = require('slug')

function CreateCourse({ onUpdate }) {
	const [dialog, setDialog] = useState(false)

	function toggleDialog() {
		setDialog(!dialog)
	}

	const [title, setTitle] = useState('')
	const [slug, setSlug] = useState('')
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [instructors, setInstructors] = useState([])
	const [assistants, setAssistants] = useState([])
	const [description, setDescription] = useState('')
	const [pickList, setPickList] = useState([])

	useEffect(function () {
		;(async function () {
			const response = await fetchapi('/admin/courses/instructors')

			if (response) {
				setPickList(
					response.instructors?.map(function (i) {
						return {
							label: `${i.firstname} ${i.lastname}`,
							value: i.userid,
						}
					}) || []
				)
			}
		})()
	}, [])

	async function createCourse() {
		const data = {
			title,
			slug,
			startDate: startDate,
			endDate: endDate,
			instructors: JSON.stringify(instructors),
			assistants: JSON.stringify(assistants),
			description,
		}
		const response = await fetchapi('/admin/courses/create', data)

		if (response?.success) {
			setDialog(false)
			onUpdate && onUpdate()
		}
	}

	function slugifyTitle(title) {
		setSlug(slugify(title))
		setTitle(title)
	}

	return (
		<>
			<Button
				type='button'
				title='New Course'
				onClick={toggleDialog}
				appearance='primary'
			>
				<span className='icon fa-solid fa-plus'></span>
				<span>New Course</span>
			</Button>
			<Modal id='modal-new-course' className='modal-lg' open={dialog}>
				<Modal.Header>
					<Modal.Title>Create a New Course</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form className='form-grid'>
						<Container>
							<Row>
								<Col xs={24}>
									<Form.ControlLabel>Name</Form.ControlLabel>
									<Input type='input' onChange={slugifyTitle} />
								</Col>
							</Row>
							<Row>
								<Col xs={24}>
									<Form.ControlLabel>URL</Form.ControlLabel>
									<InputGroup inside>
										<InputGroup.Addon id='course-slug-prefix'>/course/</InputGroup.Addon>
										<Input type='input' value={slug} onChange={setSlug} />
									</InputGroup>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<Form.ControlLabel>Start Date</Form.ControlLabel>
									<Input type='date' onChange={setStartDate} />
								</Col>
								<Col xs={12}>
									<Form.ControlLabel>End Date</Form.ControlLabel>
									<Input type='date' onChange={setEndDate} />
								</Col>
							</Row>
							<Row>
								<Col xs={24}>
									<Form.ControlLabel>Instrutor(s)</Form.ControlLabel>
									<TagPicker block data={pickList} onChange={setInstructors} />
								</Col>
							</Row>
							<Row>
								<Col xs={24}>
									<Form.ControlLabel>Assistant(s)</Form.ControlLabel>
									<TagPicker block data={pickList} onChange={setAssistants} />
								</Col>
							</Row>
							<Row>
								<Col xs={24}>
									<Form.ControlLabel>Description</Form.ControlLabel>
									<AppEditor onChange={setDescription} />
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button appearance='subtle' onClick={toggleDialog}>
						Cancel
					</Button>
					<Button appearance='primary' onClick={createCourse}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CreateCourse
