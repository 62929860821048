import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.tz.setDefault(dayjs.tz.guess())

const DATE_FORMAT = 'MMM D, YYYY'
const TIME_FORMAT = 'h:mm a'
const DATE_TIME = 'MMM D, YYYY @ h:mm a'

const TODAY = new dayjs()
const YESTERDAY = new dayjs().subtract(1, 'day')
const LAST_WEEK = new dayjs().subtract(1, 'week')

function casualDateFormat(dateStr) {
	const date = new dayjs(dateStr)
	let output

	if (date.isSame(TODAY)) {
		output = 'Today'
	} else if (date.isSame(YESTERDAY)) {
		output = 'Yesterday'
	} else if (date.isSame(LAST_WEEK)) {
		output = date.format('ddd')
	} else {
		output = date.format(DATE_FORMAT)
	}

	return output
}

function casualDateTimeFormat(dateStr) {
	const date = new dayjs(dateStr)
	let output

	if (date.isSame(TODAY, 'day')) {
		output = `Today ${date.format(TIME_FORMAT)}`
	} else if (date.isSame(YESTERDAY, 'day')) {
		output = `Yesterday ${date.format(TIME_FORMAT)}`
	} else if (date.isSameOrAfter(LAST_WEEK, 'day')) {
		output = date.format(`ddd ${TIME_FORMAT}`)
	} else {
		output = date.format(`${DATE_FORMAT} ${TIME_FORMAT}`)
	}

	return output
}

export {
	dayjs,
	DATE_FORMAT,
	TIME_FORMAT,
	DATE_TIME,
	TODAY,
	YESTERDAY,
	casualDateFormat,
	casualDateTimeFormat,
}
