import { fetchapi } from 'lib/fetch'
import { dayjs } from 'lib/datetime'

export async function save(field, id, value, onSave) {
	var response
	try {
		if (value instanceof Date) {
			response = await fetchapi('/admin/edit', {
				id,
				field,
				value: dayjs(value).format('YYYY-MM-DD'),
			})
		} else {
			response = await fetchapi('/admin/edit', { id, field, value })
		}

		if (response?.success) {
			onSave && onSave()
		}

		return response
	} catch (err) {
		return {
			success: false,
			message: err.message,
		}
	}
}
