import { useState, createContext, useContext, useEffect, useTransition } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { fetchapi, clone } from 'lib/fetch'

const CourseContext = createContext()

export const useCourseContext = () => useContext(CourseContext)

export function CourseProvider({ children }) {
	const navigateTo = useNavigate()
	const location = useLocation()

	const [courseid, setCourseid] = useState(0)
	const [course, setCourse] = useState(null)
	const [lessons, setLessons] = useState([])
	const [activeLesson, setActiveLesson] = useState(null)
	const [lessonid, setLessonid] = useState(0)
	const [steps, setSteps] = useState([])
	const [activeStep, setActiveStep] = useState(null)
	const [isAdmin, setIsAdmin] = useState(false)

	const params = useParams()

	async function updateCourse() {
		if (courseid > 0) {
			const response = await fetchapi(`/course/${courseid}`)

			if (response?.success) {
				setCourse(response.course)
				setCourseid(response.course.id)
				setLessons(response.course?.lessons || [])
				setSteps(response.course?.lessons?.[0]?.steps || [])

				if (lessonid > 0) {
					setActiveLesson(response.course?.lessons?.[lessonid - 1])
					setSteps(response.course?.lessons?.[lessonid - 1].steps || [])
				}
			}
		} else {
			setCourse(null)
			setLessons([])
			setSteps([])

			setActiveLesson(null)
			setActiveStep(null)
		}
	}

	useEffect(() => {
		if (params.lessonid) {
			setLessonid(params.lessonid)
		}
		if (params.courseid) {
			setCourseid(params.courseid)
		}
	}, [params])

	useEffect(() => {
		updateCourse()
	}, [courseid])

	useEffect(() => {
		setIsAdmin(/^\/admin\//.test(location.pathname))
	}, [location])

	function updateSteps(steps) {
		setSteps(steps || [])
	}

	// 1-based index
	function gotoLesson(n) {
		console.log('gotoLesson', n)
		console.log('lessons', lessons)
		if (n > 0 && n <= lessons.length) {
			const l = clone(lessons[n - 1])
			setActiveLesson(l)
			navigateTo(
				`${isAdmin ? '/admin' : ''}/course/${courseid}/${lessons[n - 1].sortorder}`
			)
		} else {
			setActiveLesson(null)
		}
	}

	// 1-based index
	function gotoStep(n) {
		console.log('gotoStep', n)
		if (n > 0 && n <= steps.length) {
			setActiveStep(clone(steps[n - 1]))
		} else {
			setActiveStep(null)
		}
	}

	return (
		<CourseContext.Provider
			value={{
				isAdmin,
				course,
				courseid,
				updateCourse,
				lessons,
				lessonid,
				activeLesson,
				gotoLesson,
				steps,
				activeStep,
				gotoStep,
				updateSteps,
			}}
		>
			{children}
		</CourseContext.Provider>
	)
}
