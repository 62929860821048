import { useEffect, useState } from 'react'
import { Container, Content } from 'rsuite'

import AdminFrame from 'components/Admin/AdminFrame'
import CreateCourse from 'components/Admin/Courses/CreateCourse'
import CourseCatalog from 'components/Admin/Courses/CourseCatalog'
import { fetchapi } from 'lib/fetch'

import 'components/Courses/Courses.css'

function Courses() {
	const [courses, setCourses] = useState([])

	async function getCourses() {
		const response = await fetchapi('/admin/courses')

		if (response.success) {
			setCourses(response.courses)
		}
	}
	useEffect(function () {
		;(async function () {
			await getCourses()
		})()
	}, [])

	return (
		<AdminFrame>
			<Container>
				<Content>
					<h1>All Courses</h1>
					<CreateCourse onUpdate={getCourses} />
					<CourseCatalog courses={courses} onUpdate={getCourses} />
				</Content>
			</Container>
		</AdminFrame>
	)
}

export default Courses
