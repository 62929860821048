import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import { Animation, IconButton, Stack, Text } from 'rsuite'
import { TbHeart, TbHeartFilled, TbMessage, TbShare2 } from 'react-icons/tb'
import CreateComment from 'components/Post/CreateComment'
import CommentTree from 'components/Comments/CommentTree'
import AppLink from 'components/App/AppLink'
import { fetchapi } from 'lib/fetch'
import { usePostContext } from 'components/Post/PostContext'

export default function CardFooter() {
	const { post } = usePostContext()

	const color = '#FFAC2A'
	const [showComments, setShowComments] = useState(false)

	const iconHearted = <TbHeartFilled color={color} />
	const iconUnhearted = <TbHeart />

	const [hearted, setHearted] = useState(false)
	const [hearts, setHearts] = useState(0)
	const [iconHeart, setIconHeart] = useState(iconUnhearted)

	useEffect(
		function () {
			if (post?.hearts) {
				setHearts(post?.hearts)
			}
		},
		[post?.hearts]
	)

	useEffect(
		function () {
			if (post?.hearted) {
				setHearted(post?.hearted)
				setIconHeart(iconHearted)
			}
		},
		[post?.hearted]
	)

	async function toggleHeart() {
		const action = hearted ? 'unheart' : 'heart'
		const res = await fetchapi(`/post/${post?.postid}/${action}`)

		if (res.success) {
			setHearted(!hearted)
			setHearts(res.hearts)

			if (action == 'heart') {
				setIconHeart(iconHearted)
			} else {
				setIconHeart(iconUnhearted)
			}
		}
	}

	return (
		<Card.Footer>
			<Stack direction='column'>
				<Stack.Item>
					<span className='interactions'>
						<IconButton
							icon={iconHeart}
							className='heart card-btn'
							onClick={toggleHeart}
							data-hearted={hearted}
							as={AppLink}
						>
							<span className='heart-count card-btn-label'>{hearts}</span>
						</IconButton>
						<IconButton
							icon={<TbMessage />}
							className='card-btn'
							as={AppLink}
							onClick={() => setShowComments(!showComments)}
						>
							<span className='coment-count card-btn-label'>{post?.comments?.count}</span>
						</IconButton>
						<IconButton icon={<TbShare2 />} className='card-btn' />
					</span>
				</Stack.Item>
				<Stack.Item alignSelf='stretch'>
					<Animation.Collapse in={showComments}>
						{(props, ref) => <CommentBlock {...props} ref={ref} />}
					</Animation.Collapse>
				</Stack.Item>
			</Stack>
		</Card.Footer>
	)
}

const CommentBlock = React.forwardRef((props, ref) => {
	const { post, postid } = usePostContext()

	return (
		<div {...props} ref={ref}>
			<Stack direction='column' className='post-comments' spacing={30}>
				<Stack.Item alignSelf='stretch'>
					<Text weight='semibold' style={{ marginBottom: '10px' }}>
						Reply to this post
					</Text>
					<CreateComment postid={postid} />
				</Stack.Item>
				<Stack.Item alignSelf='stretch'>
					<CommentTree comments={post?.comments?.tree} />
				</Stack.Item>
			</Stack>
		</div>
	)
})
