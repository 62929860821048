import { Message } from 'rsuite'

export function BadCredentials() {
	return (
		<Message showIcon type='error'>
			The username and/or password is incorrect. Please try again.
		</Message>
	)
}

export function EmailSent() {
	return (
		<Message showIcon type='success'>
			The email was sent.
		</Message>
	)
}

export function EmailFailed({ error }) {
	return (
		<Message showIcon type='error'>
			The email could not be sent: {error}
		</Message>
	)
}