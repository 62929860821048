import React from 'react'
import { Link } from 'react-router-dom'

const AppLink = React.forwardRef(function (props, ref) {
	const { href, children, className, ...rest } = props

	return (
		<Link className={`app-link ${className}`} ref={ref} to={href} {...rest}>
			{children}
		</Link>
	)
})

export default AppLink
