import { useEffect, useState } from 'react'
import { useCourseContext } from './CourseContext'
import { fetchapi } from 'lib/fetch'
import InfiniteScroll from 'react-infinite-scroll-component'
import FeedCard from 'components/Feed/FeedCard'

export default function DiscussionFeed({ id }) {
	const { courseid } = useCourseContext()

	const [feed, setFeed] = useState([])
	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(false)

	async function refreshFeed(reset) {
		if (reset) {
			setPage(1)
		}

		const response = await fetchapi(`/course/${courseid}/${id}/discussion`, { page })
		const newItems = response.feed.map(function (item) {
			return <FeedCard key={item.postid} item={item} />
		})

		setPage(page + 1)
		setHasMore(response.hasMore)

		if (reset) {
			setFeed(newItems)
		} else {
			setFeed([...feed, newItems])
		}
	}

	useEffect(() => {
		refreshFeed(true)
	}, [id])

	return (
		<InfiniteScroll
			dataLength={feed.length}
			next={refreshFeed}
			hasMore={hasMore}
			loader={<div className='loading-text'>Loading...</div>}
			pullDownToRefresh={true}
			pullDownToRefreshContent={<div className='refresh-pull'>Pull down to refresh</div>}
			releaseToRefreshContent={<div className='refresh-release'>Release to refresh</div>}
			refreshFunction={() => refreshFeed(true)}
			endMessage={<div className='scroll-end'>That's all there is for now.</div>}
		>
			{feed}
		</InfiniteScroll>
	)
}
