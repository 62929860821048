import { useEffect, useState } from 'react'
import { TbEye, TbEyeOff } from 'react-icons/tb'
import { Button, Input, InputGroup, Whisper } from 'rsuite'

const BULLET = '•'

export default function Password({ onChange, placeholder, size, tooltip, tooltipTrigger, tooltipPlacement }) {
	const [plain, setPlain] = useState('')
	const [isMasked, setIsMasked] = useState(true)

	function toggleMask() {
		setIsMasked(!isMasked)
	}

	function updatePassword(str) {
		if (str.length > plain.length) {
			// add characters
			const newChar = str.substring(str.length - 1)
			setPlain(plain + newChar)
		} else {
			// deleting characters
			const newLength = str.length
			setPlain(plain.substring(0, newLength))
		}
	}

	useEffect(() => {
		onChange && onChange(plain)
	}, [plain])

	const passwordField = (
		<>

		</>
	)

	return (
		<>
			{tooltip ? (
				<InputGroup size={size || 'md'} style={{ fontFamily: 'monospace' }}>
					<Whisper trigger={tooltipTrigger || 'focus'} speaker={tooltip} placement={tooltipPlacement || 'right'}>
						<Input
							type={isMasked ? 'password' : 'text'}
							value={plain}
							placeholder={placeholder}
							autoCapitalize='off'
							onChange={setPlain}
						/>
					</Whisper>
					<InputGroup.Addon >
						<Button size='sm' onClick={() => toggleMask()}>
							{isMasked ? (
								<TbEyeOff size={20} />
							) : (
								<TbEye size={20} />
							)}
						</Button>
					</InputGroup.Addon>
				</InputGroup >
			) : (
				<>
					<InputGroup size={size || 'md'} style={{ fontFamily: 'monospace' }}>
						<Input
							type={isMasked ? 'password' : 'text'}
							value={plain}
							placeholder={placeholder}
							onChange={setPlain}
						/>
						<InputGroup.Addon >
							<Button size='sm' onClick={() => toggleMask()}>
								{isMasked ? (
									<TbEyeOff size={20} />
								) : (
									<TbEye size={20} />
								)}
							</Button>
						</InputGroup.Addon>
					</InputGroup>
				</>
			)}

		</>
	)
}