import { configureRefreshFetch } from 'refresh-fetch'
import { get, multiPut } from 'lib/storage'
import { useGlobalContext } from 'components/App/GlobalContext'

const server = process.env.REACT_APP_BACKEND_URL
const origin = process.env.REACT_APP_URL

export function clone(obj) {
	if (obj) {
		return JSON.parse(JSON.stringify(obj))
	} else {
		return obj
	}
}

function needsRefresh(error) {
	return error.status === 401
}

function convertToQueryString(obj) {
	if (window.cordova) {
		return obj
	}

	let output = []

	for (let k in obj) {
		output.push(`${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
	}

	return output.join('&')
}

function doRefresh() {
	return get('RefreshToken').then(function (refreshToken) {
		return fetchJSON(`${server}/users/reauth`, {
			method: 'POST',
			headers: {
				//'Origin': origin,
				'Access-Token': refreshToken,
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
		}).then(function (res) {
			const access = ['AuthToken', res.body.access]
			const refresh = ['RefreshToken', res.body.refresh]
			const profile = ['Profile', res.body.profile || {}]

			const args = [access, refresh]
			if (profile) {
				args.push(profile)
			}
			return multiPut(args)
		})
	})
}

function doPost(url, data) {
	return get('AuthToken').then(function (authToken) {
		return fetchJSON(`${server}${url}`, {
			method: 'POST',
			headers: {
				//'Origin': origin,
				'Access-Token': authToken,
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: convertToQueryString(data),
		}).then(function (res) {
			return res.body
		})
	})
}

export const fetchapi = configureRefreshFetch({
	shouldRefreshToken: needsRefresh,
	refreshToken: doRefresh,
	fetch: doPost,
})

function doUpload(url, formData) {
	return get('AuthToken').then(function (authToken) {
		return fetch(`${server}${url}`, {
			method: 'POST',
			headers: {
				//'Origin': process.env.REACT_APP_URL,
				'Access-Token': authToken,
				// Omit content-type; browser will fill it in
			},
			body: formData,
		}).then((response) => {
			return getResponseBody(response).then((body) => ({
				response,
				body,
			}))
		}).then(checkStatus).then(function ({ body }) {
			return body
		})
	})
}

export const upload = configureRefreshFetch({
	shouldRefreshToken: needsRefresh,
	refreshToken: doRefresh,
	fetch: doUpload,
})

// from refresh-fetch/src/fetchJSON.js
const fetchJSON = (url, options) => {
	if (window.cordova) {
		console.log('Using Cordova Advanced HTTP')
		return _fetch_cordova(url, options).then(function (response) {
			return getResponseBody(response).then(function (body) {
				return {
					response: response,
					body: body
				}
			})
		}).then(checkStatus)
	} else {
		console.log('Using native Fetch API')
		return fetch(url, options).then(function (response) {
			console.log('response', response)
			return getResponseBody(response).then(function (body) {
				return {
					response: response,
					body: body
				}
			})
		}).then(checkStatus)
	}
}

const _fetch_cordova = (url, options) => {
	options.data = (options?.body ? Object.assign({}, options.body) : {})
	options.serializer = 'urlencoded'
	console.log(url, options)

	return new Promise((resolve, reject) => {
		window.cordova.plugin?.http?.sendRequest(
			url,
			options,
			(res) => {
				console.log('res', res)
				resolve({
					status: res.status,
					ok: true,
					url: url,
					headers: res.headers,
					body: res.data
				})
			},
			(res) => {
				console.log('res', res)
				// error
				try {
					const response = JSON.parse(res.error)
					reject({
						status: res.status,
						ok: false,
						error: res.error,
						headers: res.headers,
						url: res.url
					})
				} catch (err) {
					reject(reject({
						status: 500,
						ok: false,
						error: err.message,
						headers: {},
						url: url
					}))
				}
			}
		)
	})
}

const getResponseBody = (response) => {
	const contentType = response.headers.get('content-type')
	return contentType && contentType.indexOf('json') >= 0
		? response.clone().text().then(tryParseJSON)
		: response.clone().text()
}

const tryParseJSON = (json) => {
	if (!json) {
		return null
	}

	try {
		return JSON.parse(json)
	} catch (e) {
		throw new Error(`Failed to parse unexpected JSON response: ${json}`)
	}
}

function ResponseError(status, response, body) {
	this.name = 'ResponseError'
	this.status = status
	this.response = response
	this.body = body
}

ResponseError.prototype = Error.prototype

const checkStatus = ({ response, body }) => {
	if (response.ok) {
		return { response, body }
	} else {
		throw new ResponseError(response.status, response, body)
	}
}
