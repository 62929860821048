import { useState, useEffect, createContext, useContext, useTransition } from 'react'
import { get, remove } from 'lib/storage'
import { useToaster, useMediaQuery } from 'rsuite'
import Splash from 'components/Splash/Splash'
import SignIn from 'components/SignIn/SignIn'
import { put } from 'lib/storage'
import { BadCredentials } from './AppMessages'
import { fetchapi } from 'lib/fetch'

const GlobalContext = createContext()

export const useGlobalContext = () => useContext(GlobalContext)

export default function GlobalProvider({ children }) {
	const toaster = useToaster()

	const [showSplash, setShowSplash] = useState(true)
	const [showSignIn, setShowSignIn] = useState(false)
	const [socket, setSocket] = useState(null)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [profile, setProfile] = useState({})
	const [isAdmin, setIsAdmin] = useState(false)
	const [groups, setGroups] = useState([])
	const [settings, setSettings] = useState({})
	const [isMobile] = useMediaQuery('(max-width: 991px)')

	useEffect(() => {
		; (async () => {
			const p = await get('Profile')

			if (p?.id) {
				setProfile(p)
				setIsLoggedIn(true)
				setIsAdmin(p.isAdmin)
				setShowSignIn(false)

				const g = await get('Groups')
				if (g?.length) {
					setGroups(g)
				}

				const s = await get('Settings')
				if (s && Object.keys(s).length > 0) {
					setSettings(s)
				}
			} else {
				setShowSignIn(true)
			}

		})()
	}, [])

	function login(email, password) {
		; (async () => {
			let response

			try {
				response = await fetchapi('/users/auth', {
					email,
					password,
				})

				if (response?.success) {
					put('AuthToken', response.access)
					put('RefreshToken', response.refresh)

					put('Profile', response.profile || {})
					setProfile(response.profile || {})

					put('Groups', response.profile.groups || [])
					setGroups(response.profile.groups || [])

					put('Settings', response.settings || {})
					setSettings(response.settings || {})

					setIsAdmin(response.profile.isAdmin)
					setIsLoggedIn(true)

					setTimeout(() => {
						setShowSplash(false)
					}, 2000)
				}
			} catch (err) {
				if (err?.status >= 400 && err?.status < 500) {
					popMessage(<BadCredentials />, 'topCenter', 2000)
				} else {
					console.log(err)
				}
			}
		})()
	}

	function logout() {
		try {
			setIsLoggedIn(false)
			remove('AuthToken')
			remove('RefreshToken')
			remove('Profile')
			remove('Groups')
			remove('Settings')
			setShowSignIn(true)
		} catch (err) {
			console.log(err)
		}
	}

	async function popMessage(msg, placement, duration) {
		const toastId = await toaster?.push(msg, {
			placement: placement || 'bottomCenter'
		})
		setTimeout(() => {
			toaster.remove(toastId)
		}, duration || 5000)
	}

	function openLink(url) {

	}

	return (
		<GlobalContext.Provider
			value={{
				socket, setSocket,
				isLoggedIn, setIsLoggedIn,
				isAdmin, setIsAdmin,
				profile, setProfile,
				groups, setGroups,
				popMessage,
				login, logout,
				openLink,
				settings,
				isMobile,
			}}
		>
			{isLoggedIn ? (
				<>
					{children}
				</>
			) : (
				showSignIn && <SignIn />
			)}
		</GlobalContext.Provider>
	)
}
