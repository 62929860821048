import { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Table,
	Dropdown,
	Popover,
	Whisper,
	IconButton,
	Text,
	Modal,
	Panel,
	Button,
	Toggle,
	Tooltip,
} from 'rsuite'
import { TbDots, TbCopy, TbTrash } from 'react-icons/tb'

import { fetchapi } from 'lib/fetch'
import { save } from 'lib/edit'
import { dayjs } from 'lib/datetime'

const { Column, HeaderCell, Cell } = Table

function listInstructors(list) {
	return (
		<ul className='instructor-list'>
			{list.map(function (v, i) {
				return (
					<li key={i}>
						{v.firstname} {v.lastname}
					</li>
				)
			})}
		</ul>
	)
}

function CourseCatalog({ courses, onUpdate }) {
	return (
		<Table className='course-catalog' hover autoHeight data={courses} rowKey='groupid'>
			<Column flexGrow={2} sortable fullText>
				<HeaderCell>Name</HeaderCell>
				<Cell>
					{(rowData) => (
						<Link to={`/admin/course/${rowData.groupid}`}>
							{rowData.name}
						</Link>
					)}
				</Cell>
			</Column>
			<Column>
				<HeaderCell />
				<ActionCell onUpdate={onUpdate} />
			</Column>
			<Column>
				<HeaderCell>Enabled</HeaderCell>
				<ToggleCell />
			</Column>
			<Column flexGrow={1} fullText>
				<HeaderCell>Enrollment</HeaderCell>
				<Cell align='center'>
					{(rowData) => (
						<Link to={`/admin/course/${rowData.groupid}/roster`}>
							{rowData.enrollment.length}
						</Link>
					)}
				</Cell>
			</Column>
			<Column flexGrow={1} sortable fullText>
				<HeaderCell>Start Date</HeaderCell>
				<Cell>
					{function (rowData) {
						return dayjs(rowData.startdate).format('ll')
					}}
				</Cell>
			</Column>
			<Column flexGrow={1} sortable fullText>
				<HeaderCell>End Date</HeaderCell>
				<Cell>
					{function (rowData) {
						return dayjs(rowData.enddate).format('ll')
					}}
				</Cell>
			</Column>
			<Column flexGrow={2} sortable fullText>
				<HeaderCell>Instructor</HeaderCell>
				<Cell>
					{function (rowData) {
						return listInstructors(rowData.instructors)
					}}
				</Cell>
			</Column>
			<Column flexGrow={2} sortable fullText>
				<HeaderCell>Assistant</HeaderCell>
				<Cell>
					{function (rowData) {
						return listInstructors(rowData.assistants)
					}}
				</Cell>
			</Column>
		</Table>
	)
}

export default CourseCatalog

function ActionCell({ rowData, dataKey, onUpdate, ...props }) {
	const popoverRef = useRef()
	const [deleteDialog, setDeleteDialog] = useState(false)

	async function copyCourse() {
		try {
			popoverRef.current.close()
			const response = await fetchapi(`/admin/course/${rowData.groupid}/copy`)

			if (response?.success) {
				onUpdate && onUpdate()
			}
		} catch (err) {
			console.log(err)
		}
	}

	function deleteCourse() { }

	const menu = (
		<Popover full>
			<Dropdown.Menu>
				<Dropdown.Item eventKey={1} onSelect={copyCourse}>
					<Text>
						<TbCopy /> Duplicate Course
					</Text>
				</Dropdown.Item>
				<Dropdown.Separator />
				<Dropdown.Item
					eventKey={2}
					onSelect={() => {
						setDeleteDialog(true)
						popoverRef.current.close()
					}}
				>
					<Text color='red'>
						<TbTrash /> Delete Course
					</Text>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Popover>
	)

	return (
		<>
			<Cell {...props} className='link-group'>
				<Whisper ref={popoverRef} placement='bottomEnd' trigger='click' speaker={menu}>
					<IconButton
						className='course-action-menu'
						appearance='subtle'
						icon={<TbDots />}
					/>
				</Whisper>
			</Cell>
			<Modal
				size='xs'
				backdrop
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
			>
				<Modal.Header>
					<Modal.Title>Delete Course?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete course:
					<Panel bordered style={{ borderColor: 'red' }}>
						<strong>{rowData.name}</strong>
					</Panel>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setDeleteDialog(false)} appearance='subtle'>
						Cancel
					</Button>
					<Button onClick={() => deleteCourse()} appearance='primary' color='red'>
						DELETE
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

function ToggleCell({ rowData, dataKey, ...props }) {
	const [isActive, setIsActive] = useState(false)
	const [groupId, setGroupId] = useState()
	const now = dayjs()
	const startdate = dayjs(rowData.startdate)
	const enddate = dayjs(rowData.enddate)
	const isInProgress =
		startdate.isSameOrBefore(now, 'day') && now.isSameOrBefore(enddate, 'day')

	useEffect(() => {
		setIsActive(rowData.status === 'active')
		setGroupId(rowData.groupid)
	}, [rowData])

	async function enableCourse(status) {
		try {
			const response = await save('courseStatus', groupId, status ? 'active' : 'inactive')
			console.log(response)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<Cell {...props}>
			<Toggle checked={isActive} disabled={isInProgress} onChange={enableCourse} />
		</Cell>
	)
}
