import AppFrame from 'components/App/AppFrame'
import Feed from 'components/Feed/Feed'
import Saved from 'components/Feed/Saved'

import './FeedFrame.css'

function FeedFrame({ src }) {
	try {
		let feed

		switch (src) {
			case 'saved':
				feed = <Saved />
				break

			default:
				feed = <Feed />
				break
		}

		return (
			<AppFrame colSpan={24} xxl={12} xl={15} lg={18} md={24} sm={24} xs={24}>
				{feed}
			</AppFrame>
		)
	} catch (err) {
		console.error(err)
	}
}

export default FeedFrame
