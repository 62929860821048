import Dashboard from 'components/Admin/Dashboard'
import InviteUsers from 'components/Admin/InviteUsers'
import Users from 'components/Admin/Users'
import Courses from 'components/Admin/Courses/Courses'
import EditCourse from 'components/Admin/Courses/EditCourse'
import EditLesson from 'components/Admin/Courses/EditLesson'
import Settings from 'components/Admin/Settings/Settings'
import Roster from 'components/Admin/Courses/Roster'
import Events from 'components/Admin/Events/Events'

const adminRoutes = [
	{
		path: '/admin',
		element: <Dashboard />,
	},
	{
		path: '/admin/users',
		element: <Users />,
	},
	{
		path: '/admin/users/invite',
		element: <InviteUsers />,
	},
	{
		path: '/admin/courses',
		element: <Courses />,
	},
	{
		path: '/admin/course/:courseid',
		element: <EditCourse />,
	},
	{
		path: '/admin/course/:courseid/roster',
		element: <Roster />,
	},
	{
		path: '/admin/course/:courseid/:lessonid',
		element: <EditLesson />,
	},
	{
		path: '/admin/settings',
		element: <Settings />,
	},
	{
		path: '/admin/events',
		element: <Events />
	}
]

export default adminRoutes
