import AsyncStorage from '@react-native-async-storage/async-storage'
import secureLocalStorage from 'react-secure-storage'
import { isNative } from './utils'

const isProduction = false// process.env.NODE_ENV === 'production'

// async function
export function put(key, value) {
	if (isProduction && !isNative) {
		return new Promise((resolve, reject) => {
			try {
				secureLocalStorage.setItem(key, JSON.stringify(value))
				resolve()
			} catch (err) {
				console.error(err)
				reject(err)
			}
		})
	} else {
		return AsyncStorage.setItem(key, JSON.stringify(value))
	}
}

//async function
export function multiPut(multiValue) {
	const values = multiValue.map(([key, value]) => [key, JSON.stringify(value)])

	if (isProduction && !isNative) {
		return new Promise((resolve, reject) => {
			try {
				multiValue.forEach((v) => {
					secureLocalStorage.setItem.apply(null, v)
				})
				resolve()
			} catch (err) {
				console.error(err)
				reject(err)
			}
		})
	} else {
		return AsyncStorage.multiSet(values)
	}
}

export function has(key) {
	if (isProduction && !isNative) {
		const keys = secureLocalStorage.getAllKeys()

		return keys.includes(key)
	} else {
		; (async () => {
			const keys = await AsyncStorage.getAllKeys()

			return keys.includes(key)
		})()
	}
}

// async function
export function get(key) {
	return new Promise((resolve, reject) => {
		try {
			if (isProduction && !isNative) {
				const value = secureLocalStorage.getItem(key)

				if (value) {
					resolve(JSON.parse(value))
				} else {
					resolve(null)
				}
			} else {
				; (async () => {
					const value = await AsyncStorage.getItem(key)

					if (value) {
						resolve(JSON.parse(value))
					} else {
						resolve(null)
					}
				})()
			}
		} catch (err) {
			reject(err)
		}
	})
}

export function remove(key) {
	if (isProduction && !isNative) {
		secureLocalStorage.removeItem(key)
	}
	; (async () => {
		await AsyncStorage.removeItem(key)
	})()
}