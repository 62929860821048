import { useState, useEffect } from 'react'
import { Table, IconButton, Container } from 'rsuite'
import { TbPencil, TbDeviceFloppy } from 'react-icons/tb'

import AdminFrame from 'components/Admin/AdminFrame'

import { fetchapi } from 'lib/fetch'

const { Column, HeaderCell, Cell } = Table

function EditableCell({ rowData, dataKey, onChange, ...props }) {
	const editing = rowData.status === 'EDIT'
	return (
		<Cell {...props} className={editing ? 'table-content-editing' : ''} dataKey={dataKey}>
			{editing ? (
				<input
					className='rs-input table-cell-editable'
					defaultValue={rowData[dataKey]}
					onChange={(event) => {
						onChange && onChange(rowData.id, dataKey, event.target.value)
					}}
				/>
			) : (
				<span className='table-content-edit-span'>{rowData[dataKey]}</span>
			)}
		</Cell>
	)
}

function ActionCell({ rowData, dataKey, onClick, ...props }) {
	return (
		<Cell {...props} style={{ padding: '6px' }} dataKey={dataKey}>
			<IconButton
				icon={rowData.status === 'EDIT' ? <TbDeviceFloppy /> : <TbPencil />}
				onClick={function () {
					onClick(rowData.id)
				}}
			/>
		</Cell>
	)
}

function DateCell({ rowData, dataKey, ...props }) {
	const d = new Date(rowData.joined)

	return (
		<Cell {...props} dataKey={dataKey}>
			{d.toLocaleDateString()}
		</Cell>
	)
}

export default function Users() {
	const [users, setUsers] = useState([])
	const [sortColumn, setSortColumn] = useState();
	const [sortType, setSortType] = useState();
	const [undo, setUndo] = useState({})

	const handleChange = function (id, key, value) {
		const newUsers = Object.assign([], users)

		newUsers.find(function (item) {
			return item.id === id
		})[key] = value

		setUsers(newUsers)
	}
	const handleEditState = function (id) {
		const newUsers = Object.assign([], users)
		const activeItem = newUsers.find(function (item) {
			return item.id === id
		})

		if (activeItem.status === 'EDIT') {
			; (async function () {
				const response = await fetchapi(`/admin/user/${activeItem.id}/edit`, {
					firstname: activeItem.firstname,
					lastname: activeItem.lastname,
					email: activeItem.email,
				})

				if (!response.success) {
					activeItem.firstname = undo.firstname
					activeItem.lastname = undo.lastname
					activeItem.email = undo.email
					setUndo({})
				}
			})()
		} else {
			setUndo({
				firstname: activeItem.firstname,
				lastname: activeItem.lastname,
				email: activeItem.email,
			})
		}

		activeItem.status = activeItem.status ? null : 'EDIT'
		setUsers(newUsers)
	}

	async function getUsers() {
		const response = await fetchapi('/admin/users')

		setUsers(response.users)
	}

	useEffect(function () {
		getUsers()
	}, [])

	const handleSortColumn = (sortColumn, sortType) => {
		console.log(sortColumn, sortType)

		const data = [...users].sort((v1, v2) => {
			if (v1[sortColumn] < v2[sortColumn]) {
				return -1
			} else if (v1[sortColumn] > v2[sortColumn]) {
				return 1
			} else {
				return 0
			}
		})

		if (sortType === 'desc') {
			data.reverse()
		}

		setUsers(data)
		setSortColumn(sortColumn);
		setSortType(sortType);
	};

	return (
		<AdminFrame>
			<Container>
				<h1>All Users</h1>
				<Table
					data={users}
					onSortColumn={handleSortColumn}
					sortColumn={sortColumn}
					sortType={sortType}
					autoHeight={true}
				>
					<Column align='center' sortable flexGrow={1}>
						<HeaderCell>ID</HeaderCell>
						<Cell dataKey='id' />
					</Column>
					<Column resizable sortable flexGrow={2}>
						<HeaderCell>First Name</HeaderCell>
						<EditableCell dataKey='firstname' onChange={handleChange} />
					</Column>
					<Column resizable sortable flexGrow={2}>
						<HeaderCell>Last Name</HeaderCell>
						<EditableCell dataKey='lastname' onChange={handleChange} />
					</Column>
					<Column resizable sortable flexGrow={2}>
						<HeaderCell>Email</HeaderCell>
						<EditableCell dataKey='email' onChange={handleChange} />
					</Column>
					<Column align='center' resizable sortable flexGrow={2}>
						<HeaderCell>Joined</HeaderCell>
						<DateCell dataKey='joined' />
					</Column>
					<Column flexGrow={1}>
						<HeaderCell>Actions</HeaderCell>
						<ActionCell dataKey='id' onClick={handleEditState} />
					</Column>
				</Table>
			</Container>
		</AdminFrame>
	)
}
