import { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { register } from 'swiper/element/bundle'
import { swiperConfig } from 'lib/imageGallery'
import parse from 'html-react-parser'

import Card from 'react-bootstrap/Card'

import { PostProvider, usePostContext } from 'components/Post/PostContext'
import AppFrame from 'components/App/AppFrame'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import CreateComment from 'components/Post/CreateComment'
import CommentTree from 'components/Comments/CommentTree'

import './Comments.css'

register()

function PostFrame() {
	return (
		<AppFrame colSpan={24} xxl={12} xl={15} lg={18} md={24} sm={24} xs={24}>
			<PostProvider>
				<PostContent />
			</PostProvider>
		</AppFrame>
	)
}

export default PostFrame

function PostContent() {
	const { attachments, comments, post, fetchPost } = usePostContext()
	const swiperRef = useRef(null)
	const { postid } = useParams()
	const saveClass = ['save', 'float-end']

	useEffect(
		function () {
			; (async function () {
				await fetchPost(postid)
			})()
		},
		[postid]
	)

	useEffect(() => {
		if (post?.saved) {
			saveClass.push('saved')
		}
	}, [post])

	useEffect(function () {
		if (attachments.length > 0) {
			swiperConfig(swiperRef)
		}
	})

	var imageGallery = <></>

	if (attachments.length > 0) {
		imageGallery = (
			<div className='image-gallery'>
				<swiper-container ref={swiperRef} init='false'>
					{attachments.map(function (a) {
						return (
							<swiper-slide key={a.id} lazy='true'>
								<div className='box box-1x1'>
									<div className='content'>
										<img src={a.url} loading='lazy' alt='' />
									</div>
								</div>
							</swiper-slide>
						)
					})}
				</swiper-container>
			</div>
		)
	}

	return (
		<>
			<Card className='post-card'>
				<CardHeader item={post} />
				<Card.Body>
					{imageGallery}
					<div className='card-text'>{post?.body && parse(post?.body)}</div>
				</Card.Body>
				<CardFooter item={post} />
			</Card>
			<div id='comments' className='comments-container'>
				<h4 className='comment-header'>Reply to this post</h4>
				<CreateComment postid={postid} />
				<CommentTree comments={comments?.tree} style={{ marginTop: '50px' }} />
			</div>
		</>
	)
}
