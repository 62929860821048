import { Container, Content } from 'rsuite'
import PageNotFound from 'components/App/PageNotFound'
import Header from 'components/App/Header/Header'
import AdminSidebar from 'components/Admin/AdminSidebar'
import GlobalProvider from 'components/App/GlobalContext'
import { isAdmin } from 'lib/utils'

export default function AdminFrame({ children }) {
	if (!isAdmin()) {
		return <PageNotFound />
	} else {
		return (
			<GlobalProvider>
				<Container>
					<Header />
					<Container>
						<AdminSidebar />
						<Content className='content-frame'>{children}</Content>
					</Container>
				</Container>
			</GlobalProvider>
		)
	}
}
