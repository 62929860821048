import { Dropdown, IconButton } from 'rsuite'
import { TbUser, TbDoorExit, TbSettings } from 'react-icons/tb'
import AppLink from 'components/App/AppLink'
import { useGlobalContext } from '../GlobalContext'

function UserMenu() {
	const {
		profile,
		logout
	} = useGlobalContext()

	function iconButton(props) {
		return <IconButton {...props} icon={<TbUser size={30} />} className='utility-btn' />
	}

	return (
		<Dropdown
			className='header-nav-menu'
			title='Profile'
			renderToggle={iconButton}
			placement='bottomEnd'
		>
			<Dropdown.Item panel style={{ padding: 10, textTransform: 'uppercase' }}>
				<p>
					<strong>My Profile</strong>
				</p>
			</Dropdown.Item>
			<Dropdown.Item divider />
			<Dropdown.Item as={AppLink} href='/profile' icon={<TbUser />}>
				{profile.firstname} {profile.lastname}
			</Dropdown.Item>
			{/*
			<Dropdown.Item as={AppLink} href='/settings' icon={<TbSettings />}>
				Settings &amp; Privacy
			</Dropdown.Item>
			*/}
			<Dropdown.Item divider />
			<Dropdown.Item onClick={logout} icon={<TbDoorExit />}>
				Sign out
			</Dropdown.Item>
		</Dropdown>
	)
}

export default UserMenu
