import { useState, useEffect } from 'react'
import { InlineEdit } from 'rsuite'
import { save } from 'lib/edit'

export default function Editable({ defaultValue, field, id, onSave, children, ...rest }) {
	const [value, setValue] = useState()

	useEffect(
		function () {
			if (defaultValue) {
				setValue(defaultValue)
			}
		},
		[defaultValue]
	)

	function onCancel() {
		setValue(defaultValue)
	}

	return (
		<InlineEdit
			className='inline-editable'
			value={value}
			defaultValue={defaultValue}
			onChange={setValue}
			onSave={() => {
				save(field, id, value, onSave)
			}}
			onCancel={onCancel}
			stateOnBlur='cancel'
			{...rest}
		>
			{children}
		</InlineEdit>
	)
}
