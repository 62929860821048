import { useState, useEffect } from 'react'
import { Panel, Stack, TagPicker, Button } from 'rsuite'
import { EmailEditor } from 'components/App/Editor/EmailEditor'
import Editable from '../Editable'
import { fetchapi } from 'lib/fetch'
import { sendEmail } from 'lib/utils'
import { save } from 'lib/edit'
import { EmailSent, EmailFailed } from 'components/App/AppMessages'
import { useGlobalContext } from 'components/App/GlobalContext'

export default function EmailTemplates() {
	const [templates, setTemplates] = useState()
	const [testRecipients, setTestRecipients] = useState([])

	useEffect(() => {
		; (async () => {
			let response = await fetchapi('/admin/email/templates')
			if (response?.success) {
				setTemplates(response.templates)
			}

			response = await fetchapi('/admin/settings/testrecipients')
			if (response?.success) {
				setTestRecipients(response.recipients)
			}

		})()
	}, [])

	return templates?.map((template) => (
		<EmailTemplate key={template.id} template={template} recipients={testRecipients} />
	))
}

function EmailTemplate({ template, recipients }) {
	const {
		popMessage
	} = useGlobalContext()

	const [selectedRecipients, setSelectedRecipients] = useState([])

	async function sendTestEmail() {
		const response = await sendEmail(template.id, selectedRecipients)

		if (response?.success) {
			popMessage(<EmailSent />)
		} else {
			popMessage(<EmailFailed error={response.message} />)
		}
	}

	return (
		<Panel header={template.name} bordered collapsible>
			<Stack direction='column' spacing={10}>
				<Stack.Item alignSelf='stretch'>
					<Stack direction='row' spacing={10}>
						<Stack.Item flex={1}>
							<TagPicker
								block
								placeholder='Select Test Recipients'
								data={recipients}
								valueKey='id'
								labelKey='name'
								size='lg'
								onChange={setSelectedRecipients}
							/>
						</Stack.Item>
						<Stack.Item>
							<Button
								appearance='ghost'
								onClick={() => sendTestEmail('email_inviteSubject', 'email_inviteBody')}
							>Send Test Email</Button>
						</Stack.Item>
					</Stack>
				</Stack.Item>
				<Stack.Item alignSelf='stretch'>
					<Editable
						defaultValue={template.subject}
						placeholder='Subject Line'
						field='emailSubject'
						id={template.id}
						style={{ border: '1px solid #ccc', borderRadius: '6px' }}
					/>
				</Stack.Item>
				<Stack.Item alignSelf='stretch'>
					<EmailEditor
						editable
						field='emailBody'
						id={template.id}
						data={template.body}
					/>
				</Stack.Item>
			</Stack>
		</Panel>
	)
}
