import { useEffect, useState } from 'react'

import FeedCard from 'components/Feed/FeedCard'

import { fetchapi } from 'lib/fetch'

function Feed() {
	const [savedFeed, setSavedFeed] = useState([])

	useEffect(function () {
		;(async function () {
			const response = await fetchapi('/saved')
			setSavedFeed(response.feed)
		})()
	}, [])

	const pageTitle = <h1>My Saved Posts</h1>

	if (savedFeed && savedFeed.length === 0) {
		return (
			<>
				{pageTitle}
				<p>No posts yet!</p>
			</>
		)
	} else {
		return (
			<>
				{pageTitle}
				{savedFeed.map(function (item) {
					return <FeedCard key={item.postid} item={item} />
				})}
			</>
		)
	}
}

export default Feed
