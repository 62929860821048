import { DatePicker } from 'rsuite'
import { save } from 'lib/edit'

export default function EditableDate({ defaultValue, field, id, onSave }) {
	return (
		<DatePicker
			oneTap
			appearance='subtle'
			editable
			cleanable={false}
			value={defaultValue}
			defaultValue={defaultValue}
			format='MMM d, yyyy'
			onSelect={(d) => {
				save(field, id, d, onSave)
			}}
		/>
	)
}
