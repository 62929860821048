import React from 'react'
import { Container, Content, Panel, Navbar, Nav } from 'rsuite'
import { TbArrowLeft } from 'react-icons/tb'
import parse from 'html-react-parser'
import AppFrame from 'components/App/AppFrame'
import AppLink from 'components/App/AppLink'
import LessonSteps from 'components/Courses/LessonSteps'
import { dayjs } from 'lib/datetime'
import 'components/Courses/Lesson.css'
import { CourseProvider, useCourseContext } from './CourseContext'
import { useGlobalContext } from 'components/App/GlobalContext'

export default function ViewLesson() {
	return (
		<AppFrame colSpan={24} xxl={15} xl={15} lg={18} md={24} sm={24} xs={24}>
			<CourseProvider>
				<ViewLessonContent />
			</CourseProvider>
		</AppFrame>
	)
}

function ViewLessonContent() {
	const {
		profile
	} = useGlobalContext()

	const { courseid, course, activeLesson } = useCourseContext()

	return (
		<Container className='lesson-view has-subnav'>
			<Content>
				<Navbar className='subnav'>
					<Nav>
						<Nav.Item
							eventKey='back'
							as={AppLink}
							href={`/course/${courseid}`}
							icon={<TbArrowLeft />}
						>
							Back to Lessons
						</Nav.Item>
					</Nav>
				</Navbar>
				<h1>{course?.name}</h1>
				<h2>{activeLesson?.title}</h2>
				{!profile.isAdmin && dayjs().isBefore(dayjs(activeLesson?.releasedate)) ? (
					<p>This lesson is not yet available.</p>
				) : (
					<>
						<Panel bordered>{activeLesson?.body && parse(activeLesson?.body)}</Panel>
						<LessonSteps />
					</>
				)}
			</Content>
		</Container>
	)
}
