import { useState, useEffect } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Heading } from '@ckeditor/ckeditor5-heading'
import {
	Image,
	ImageCaption,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
} from '@ckeditor/ckeditor5-image'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link, LinkImage } from '@ckeditor/ckeditor5-link'
import { List } from '@ckeditor/ckeditor5-list'
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import {
	Table,
	TableToolbar,
	TableProperties,
	TableCellProperties,
} from '@ckeditor/ckeditor5-table'
import { Mention } from '@ckeditor/ckeditor5-mention'
import { Stack, IconButton } from 'rsuite'
import CheckIcon from '@rsuite/icons/Check'
import CloseIcon from '@rsuite/icons/Close'
import { upload } from 'lib/fetch'
import { save } from 'lib/edit'

import './AppEditor.css'

export const ADMIN_TOOLBAR = [
	'undo',
	'redo',
	'|',
	'heading',
	'|',
	'bold',
	'italic',
	'underline',
	'|',
	'bulletedList',
	'numberedList',
	'blockQuote',
	'link',
	'|',
	'insertImage',
	'imageStyle:block',
	'imageStyle:side',
	'|',
	'toggleImageCaption',
	'imageTextAlternative',
	'|',
	'linkImage',
	'|',
	'mediaEmbed',
	'|',
	'insertTable',
	'tableColumn',
	'tableRow',
	'mergeTableCells',
	'tableProperties',
	'tableCellProperties',
]

export const USER_TOOLBAR = [
	'undo',
	'redo',
	'|',
	'bold',
	'italic',
	'underline',
	'|',
	'bulletedList',
	'numberedList',
	'blockQuote',
	'|',
	'link',
]

export function AppEditor({ data, toolbar, onChange, height, editable, field, id, style }) {
	const [initialValue, setInitialValue] = useState('')
	const [value, setValue] = useState('')
	const [controls, setControls] = useState(false)
	const [instance, setInstance] = useState(null)

	const config = {
		language: 'en',
		plugins: [
			Essentials,
			Alignment,
			Bold,
			Italic,
			Underline,
			BlockQuote,
			Image,
			ImageCaption,
			ImageStyle,
			ImageToolbar,
			ImageUpload,
			LinkImage,
			Heading,
			Table,
			TableToolbar,
			TableProperties,
			TableCellProperties,
			MediaEmbed,
			List,
			Link,
			Indent,
			Mention,
		],
		extraPlugins: [CustomUploadAdapter, DisallowNestingTables],
		toolbar: {
			items: toolbar || USER_TOOLBAR,
			shouldNotGroupWhenFull: true
		},
		mediaEmbed: {
			previewsInData: true,
		},
	}

	useEffect(() => {
		setValue(data)
		setInitialValue(data)
	}, [data])

	const ckeditor = (
		<div style={style}>
			<CKEditor
				editor={ClassicEditor}
				config={config}
				data={value || ''}
				onChange={(event, editor) => {
					const data = editor.getData()

					onChange && onChange(data)
					setValue(data)
				}}
				onFocus={(event, editor) => {
					setControls(true)
				}}
				onReady={(editor) => {
					setInstance(editor)

					if (!isNaN(height)) {
						editor.editing.view.change((writer) => {
							writer.setStyle(
								'height',
								`${height || 200}px`,
								editor.editing.view.document.getRoot()
							)
						})
					}
				}}
			/>
		</div>
	)

	async function onSave() {
		if (field && id) {
			const response = await save(field, id, value)

			if (response?.success) {
				setInitialValue(value)
				setControls(false)
			}
		}
	}

	function onCancel() {
		instance.setData(initialValue)
		setValue(initialValue)
		setControls(false)
	}

	const classes = []

	if (editable) {
		classes.push('rs-inline-edit')
	}

	if (height === 'fluid') {
		classes.push('fluid-height')
	}

	return (
		<div className={classes.join(' ')} style={{ display: 'block' }}>
			{ckeditor}
			{editable && controls && (
				<Stack className='rs-inline-edit-controls rs-stack' direction='row' spacing={6}>
					<Stack.Item>
						<IconButton
							icon={<CheckIcon />}
							onClick={onSave}
							size='sm'
							aria-label='Save'
						/>
					</Stack.Item>
					<Stack.Item>
						<IconButton
							icon={<CloseIcon />}
							onClick={onCancel}
							size='sm'
							aria-label='Cancel'
						/>
					</Stack.Item>
				</Stack>
			)}
		</div>
	)
}

function CustomUploadAdapter(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
		return new GLAUploadAdapter(loader)
	}
}

class GLAUploadAdapter {
	constructor(loader) {
		this.loader = loader
	}

	async upload() {
		const file = await this.loader.file
		const data = new FormData()

		data.append('attachments', file)
		console.log(file)
		console.log(data)
		return upload('/admin/image/upload', data)
	}

	abort() { }
}

function DisallowNestingTables(editor) {
	editor.model.schema.addChildCheck((context, childDefinition) => {
		if (
			childDefinition.name == 'table' &&
			Array.from(context.getNames()).includes('table')
		) {
			return false
		}
	})
}
