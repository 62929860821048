import { Nav } from 'rsuite'
import { TbUsers } from 'react-icons/tb'
import AppLink from 'components/App/AppLink'
import { useGlobalContext } from 'components/App/GlobalContext'

function Groups() {
	const {
		groups
	} = useGlobalContext()

	return (Array.isArray(groups) ? groups?.filter((group) => group.id > 1).map(function (group) {
		return (
			<Nav.Item
				as={AppLink}
				href={'/group/' + group?.id}
				key={group?.id}
				eventKey={'group-' + group?.id}
				icon={<TbUsers size={20} />}
			>
				{group?.name}
			</Nav.Item>
		)
	}) : <></>)
}

export default Groups
