import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchapi } from 'lib/fetch'
import FeedCard from 'components/Feed/FeedCard'
import { IconButton } from 'rsuite'
import { TbRefresh } from 'react-icons/tb'

export default function CourseFeed({ userid, courseid }) {
	const [page, setPage] = useState(1)
	const [feed, setFeed] = useState([])
	const [hasMore, setHasMore] = useState(false)

	async function refreshFeed(reset) {
		const response = await fetchapi(`/admin/course/${courseid}/${userid}/feed`, {
			page: (reset ? 1 : page)
		})
		const newItems = response?.feed?.map(function (item) {
			return <FeedCard key={item.postid} item={item} />
		})

		setHasMore(response?.hasMore)

		if (reset) {
			setFeed(newItems)
			setPage(1)
		} else {
			setFeed([...feed, newItems])
			setPage(parseInt(page) + 1)
		}

		return newItems
	}

	useEffect(() => {
		; (async () => {
			refreshFeed(true)
		})()
	}, [])

	return (
		<>
			<IconButton
				appearance='ghost'
				icon={<TbRefresh />}
				onClick={() => refreshFeed(true)}
			/>
			<InfiniteScroll
				dataLength={feed.length}
				next={refreshFeed}
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				pullDownToRefresh={false}
				refreshFunction={function () {
					return refreshFeed(true)
				}}
				endMessage={<div className='scroll-end'>That's all there is for now.</div>}
			>
				{feed}
			</InfiniteScroll>
		</>
	)
}