import { useState } from 'react'
import { Button, Stack } from 'rsuite'
import { TbSend } from 'react-icons/tb'
import { AppEditor } from 'components/App/Editor/AppEditor'
import { usePostContext } from 'components/Post/PostContext'
import { fetchapi } from 'lib/fetch'

//import EventsBus from 'lib/eventsBus';

function CreateComment() {
	const { postid, fetchPost } = usePostContext()

	const [body, setBody] = useState('')

	async function onSubmit() {
		const response = await fetchapi(`/comment/create`, {
			postid,
			parentid: 0,
			body,
		})

		if (response.success) {
			setBody('')
			await fetchPost(postid)
		}
	}

	return (
		<>
			<Stack className='create-comments-container' direction='column' spacing={10}>
				<Stack.Item alignSelf='stretch'>
					<AppEditor data={body} onChange={setBody} />
				</Stack.Item>
				<Stack.Item alignSelf='flex-end'>
					<Button appearance='primary' onClick={onSubmit}>
						<TbSend size={20} style={{ marginRight: '5px' }} />
						Comment
					</Button>
				</Stack.Item>
			</Stack>
		</>
	)
}

export default CreateComment
