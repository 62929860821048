import { Container, FlexboxGrid, Col, Content } from 'rsuite'

import Header from 'components/App/Header/Header'
import DesktopNav from 'components/App/Nav/DesktopNav'
import MobileNav from 'components/App/Nav/MobileNav'
import GlobalProvider from './GlobalContext'

function AppFrame({ children, ...rest }) {
	return (
		<GlobalProvider>
			<Container {...rest}>
				<Header />
				<Container>
					<DesktopNav />
					<Content className='content-frame'>
						<FlexboxGrid justify='center'>
							<FlexboxGrid.Item as={Col} {...rest} style={{ padding: 0 }}>
								{children}
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</Content>
				</Container>
				<MobileNav />
			</Container>
		</GlobalProvider>
	)
}

export default AppFrame
