import CommentNode from 'components/Comments/CommentNode'

function CommentSubtree({ comments }) {
	if (comments.length > 0) {
		return (
			<ul>
				{comments.map(function (comment) {
					return <CommentNode key={comment.id} comment={comment} />
				})}
			</ul>
		)
	} else {
		return <></>
	}
}
export default CommentSubtree
