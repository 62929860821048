import { Sidebar, Sidenav, Nav, Col } from 'rsuite'
import { useMatch } from 'react-router-dom'
import {
	TbHome,
	TbEye,
	TbBookmark,
	TbWorld,
	TbBook2,
	TbHeadphonesFilled,
	TbTicket,
} from 'react-icons/tb'
import { RxHeartFilled } from 'react-icons/rx'
import AppLink from 'components/App/AppLink'
import GroupsSidebar from 'components/Groups/GroupsSidebar'
import { useGlobalContext } from '../GlobalContext'
import { openBrowser } from 'lib/utils'

export default function DesktopNav() {
	const {
		settings,
		profile
	} = useGlobalContext()

	return (
		<Col xsHidden smHidden mdHidden>
			<Sidebar style={{ display: 'flex', flexDirection: 'column' }} width={240} collapsible>
				<Sidenav defaultOpenKeys={['courses', 'groups']}>
					<Sidenav.Body>
						<Nav>
							<Nav.Item
								eventKey='home'
								icon={<TbHome size={20} />}
								href='/'
								active={useMatch('/')}
								as={AppLink}
							>
								Home
							</Nav.Item>
							{settings?.content_doodle?.value && (<Nav.Item href='#' as={AppLink} icon={<TbHeadphonesFilled />}>
								Doodle Devotionals
							</Nav.Item>)}
							{settings?.content_events?.value && (<Nav.Item href='#' as={AppLink} icon={<TbTicket />}>
								Events
							</Nav.Item>)}
							<Nav.Item
								as={AppLink}
								icon={<TbEye />}
								onClick={() => {
									openBrowser('https://godlovesart.com/exhibits/', '_blank')
								}}
							>
								Exhibits
							</Nav.Item>
							{settings?.content_ilaj?.value && (<Nav.Item href='#' as={AppLink} icon={<RxHeartFilled />}>
								ILA Journal
							</Nav.Item>)}
							<Nav.Item divider />
							<Nav.Item
								href='/courses'
								as={AppLink}
								eventKey='courses'
								title='Courses'
								icon={<TbBook2 size={20} />}
							>
								Courses
							</Nav.Item>
							<Nav.Item divider />
							{profile.isEmerge && settings?.content_emerge?.value && (
								<>
									<Nav.Item
										href='/emerge'
										as={AppLink}
										eventKey='groups'
										title='Emerge'
										icon={<TbWorld size={20} />}
									>
										Emerge
									</Nav.Item>
									<GroupsSidebar />
									<Nav.Item
										href='/saved'
										as={AppLink}
										eventKey='saved'
										icon={<TbBookmark size={20} />}
									>
										Saved Posts
									</Nav.Item>
									<Nav.Item divider />
								</>
							)}
						</Nav>
					</Sidenav.Body>
				</Sidenav>
			</Sidebar>
		</Col>
	)
}
