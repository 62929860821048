import { useGlobalContext } from "components/App/GlobalContext"


function ProfileHeader() {
	const { profile } = useGlobalContext()

	return (
		<>
			<div className='profile-header'>
				<h1>
					{profile.firstname} {profile.lastname}
				</h1>
			</div>
		</>
	)
}

export default ProfileHeader
