import { useEffect, useState } from 'react'
import EventsBus from 'lib/eventsBus'

import FeedCard from 'components/Feed/FeedCard'

import { fetchapi } from 'lib/fetch'

function GroupFeed({ groupid }) {
	const [feed, setFeed] = useState([])

	useEffect(
		function () {
			async function refreshFeed() {
				const response = await fetchapi('/group/' + groupid)
				setFeed(response.feed)
			}

			refreshFeed()

			EventsBus.on('createPost', refreshFeed)
		},
		[groupid]
	)

	if (feed && feed.length === 0) {
		return (
			<>
				<p>No posts yet!</p>
			</>
		)
	} else {
		return (
			<>
				{feed.map(function (item) {
					return <FeedCard key={item.postid} item={item} />
				})}
			</>
		)
	}
}

export default GroupFeed
