import { useState } from 'react'
import { Modal, Button, Text, Stack, Panel } from 'rsuite'
import { TbCameraPlus, TbPencilPlus, TbTrash } from 'react-icons/tb'
import { ReactSortable } from 'react-sortablejs'
import EventsBus from 'lib/eventsBus'
import { fetchapi, upload } from 'lib/fetch'
import { AppEditor } from 'components/App/Editor/AppEditor'
import LinkPreview from 'components/Post/LinkPreview'
import './FileUploader.css'
import { useParams } from 'react-router-dom'
import { useGlobalContext } from 'components/App/GlobalContext'

function CreatePost() {
	const {
		settings
	} = useGlobalContext()

	const params = useParams()
	const groupid = params.groupid || 1
	const token = crypto.randomUUID()
	const files = []

	const [formDisabled, setFormDisabled] = useState(false)
	const [createDialog, setCreateDialog] = useState(false)
	const [link, setLink] = useState('')

	const blankLinkPreview = {
		images: [''],
		title: '',
		siteName: '',
	}
	const [linkPreview, setLinkPreview] = useState(blankLinkPreview)
	const [loadingLinkPreview, setLoadingLinkPreview] = useState(false)

	const [postTitle, setPostTitle] = useState('')
	const [postBody, setPostBody] = useState('')

	async function toggleCreateDialog() {
		setCreateDialog(!createDialog)

		if (!createDialog) {
			setLinkPreview(blankLinkPreview)
			setLoadingLinkPreview(false)
			setPostBody('')
		}

		console.log(groupid)
	}

	function updatePostTitle(e) {
		setPostTitle(e.target.value)
	}

	const [mediaPreviews, setMediaPreviews] = useState([])

	function addFile(event) {
		var previews = [...mediaPreviews]

		for (let i = 0; i < event.target.files.length; i++) {
			const fileId = crypto.randomUUID()
			const file = event.target.files[i]
			const newFile = {
				id: fileId,
				src: URL.createObjectURL(file),
				type: file.type,
				raw: file,
			}
			previews.push(newFile)
		}

		setMediaPreviews(previews)
	}

	function removeFile(id) {
		return function () {
			const list = mediaPreviews.slice()
			const i = list.findIndex(function (v) {
				return v.id === id
			})

			if (i > -1) {
				list.splice(i, 1)
				setMediaPreviews(list)
			}
		}
	}

	async function createPost() {
		if (groupid >= 1) {
			setFormDisabled(true)

			const data = new FormData()

			data.append('groupid', groupid)
			data.append('title', postTitle)
			data.append('body', postBody)

			for (let i = 0; i < mediaPreviews.length; i++) {
				data.append('attachments', mediaPreviews[i].raw)
			}

			const res = await upload('/post/create', data)

			if (res.success) {
				EventsBus.dispatch('createPost')
				setCreateDialog(false)
			}

			setFormDisabled(false)
		}
	}

	return (settings?.content_emerge?.value &&
		<>
			<Button title='Create Post' onClick={toggleCreateDialog}>
				<TbPencilPlus size={30} />
			</Button>
			<Modal
				id='modal-create'
				className={'modal-lg' + (formDisabled ? ' disabled' : '')}
				open={createDialog}
				onClose={toggleCreateDialog}
			>
				<Modal.Header>
					<Modal.Title>Create a Post</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Stack className='modal-row' direction='column' spacing={10}>
						<Stack.Item className='form-floating mb-3' alignSelf='stretch'>
							<input
								className='form-control'
								type='text'
								name='postTitle'
								onChange={updatePostTitle}
							/>
							<label className='form-label'>Title (Optional)</label>
						</Stack.Item>
						<Stack.Item className='editor' alignSelf='stretch'>
							<Text size='xl' weight='medium' style={{ marginBottom: '0.5em' }}>
								What's on your mind?
							</Text>
							<AppEditor data={postBody} onChange={setPostBody} />
						</Stack.Item>
						<Stack.Item alignSelf='stretch'>
							<Panel bordered>
								<form id='upload-form'>
									<input
										id='upload-input'
										type='file'
										name='fileupload'
										accept='image/*'
										multiple={true}
										onChange={addFile}
									/>
									<label
										className='btn btn-outline-secondary upload-button'
										htmlFor='upload-input'
									>
										<TbCameraPlus /> Choose a File
									</label>
								</form>
								<ReactSortable
									id='upload-previews'
									list={mediaPreviews}
									setList={setMediaPreviews}
								>
									{mediaPreviews.map(function (file, seq) {
										return (
											<div
												key={file.id}
												className={
													'upload-tile box box-1x1 ui-state-default' +
													(file.loading ? ' loading' : '')
												}
											>
												<div className='content'>
													<img src={file.src} alt='' />
													<button className='upload-delete' onClick={removeFile(file.id)}>
														<TbTrash />
													</button>
												</div>
											</div>
										)
									})}
								</ReactSortable>
							</Panel>
						</Stack.Item>
					</Stack>
				</Modal.Body>
				<Modal.Footer>
					<Button appearance='subtle' onClick={toggleCreateDialog}>
						Cancel
					</Button>
					<Button appearance='primary' type='submit' onClick={createPost}>
						Post
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CreatePost
